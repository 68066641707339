/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 9/17/2021
 * @Example
 */

import React, {useState, useEffect, useRef} from "react";
import {compose, bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Col, Row, FormGroup} from "react-bootstrap";
import {makeStyles} from "diginet-core-ui/theme";
import Config from "../../../../config";
import {MForm} from "../../../common/form-material";
import CDN from "../../../CDN";
import * as W09F2091Actions from "../../../../redux/W0X/W09F2091/W09F2091_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import {
    TextInput,
    Dropdown,
    MoneyInput,
    Modal,
    ModalHeader,
    ModalBody, Typography, ModalFooter, Button, DatePicker, Attachment, Checkbox
} from 'diginet-core-ui/components';
import moment from "moment";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    customTypography: {
        margin: "20px 0 20px 0",
    },
    customTitle : {
        [theme.breakpoints.down("sm")]: {
            marginBottom: 30
        }
    }
}));
const W09F2091 = (props) => {
    const classes = useStyles();

    const _initDataForm = {
        TransID: "",
        Employee: "",
        Validdate: "",
        DutyID: "",
        DepartmentID: "",
        DivisionID: "",
        Note: "",
    };

    const initDataBase = () => {
        const dtBase = {};
        for (let i = 1; i <= 4; i++) {
            dtBase['BASE0' + i] = null;
        }
        return dtBase;
    };

    const initDataNewBase = () => {
        const dtNewBase = {};
        for (let i = 1; i <= 4; i++) {
            dtNewBase['NewBASE0' + i] = null;
        }
        return dtNewBase;
    };

    const initDataCE = () => {
        const dtCE = {};
        for (let i = 1; i <= 20; i++) {
            dtCE['CE' + (i <= 9 ? `0${i}` : i)] = null;
        }
        return dtCE;
    };

    const initDataNewCE = () => {
        const dtNewCE = {};
        for (let i = 1; i <= 20; i++) {
            dtNewCE['NewCE' + (i <= 9 ? `0${i}` : i)] = null;
        }
        return dtNewCE;
    };

    const {data, open, onClose, iPermission, getCboDuty,
              getCboDivisions, getColumns, getCoefficients, AppStatusID} = props;
    const {mode, TransID} = data || {};
    const [dataCboEmployees, setDataCboEmployees] = useState({rows: [], total: 0});
    const [errorHelperText, _setErrorHelperText] = useState({});
    const [dataOldAttachments, setDataOldAttachments] = useState([]);
    const [dataBase, setDataBase] = useState(initDataBase);
    const [dataNewBase, setDataNewBase] = useState(initDataNewBase);
    const [dataCE, setDataCE] = useState(initDataCE);
    const [dataNewCE, setDataNewCE] = useState(initDataNewCE);
    const [loading, setLoading] = useState(false);
    const [isModeView, setIsModeView] = useState(false);
    const [dataForm, setDataForm] = useState(_initDataForm);
    const [isSalaryAdjustment, setIsSalaryAdjustment] = useState(false);
    const [dataCboDepartments, setDataCboDepartments] = useState([]);
    const filterCboEmployees = useRef({skip: 0, limit: 20, strSearch: ""});
    const attachments = useRef([]);
    const deletedFile = useRef([]);
    const isChanged = useRef(false);

    const loadCboEmployees = (isReset) => {
        const param = {
            Type: "EmployeeID",
            FormID: "W09F2090",
            HostID: "",
            Language: Config.language || "84",
            skip: filterCboEmployees.current.skip,
            limit: filterCboEmployees.current.limit,
            search: filterCboEmployees.current.strSearch
        };
        setLoading(true);
        props.generalActions.getCboEmployees(param, (error, data) => {
            setLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const rows = data?.rows || data;
                const total = data?.total  || data.length;
                setDataCboEmployees({
                    rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                    total: total,
                })
            }
        });
    };

    const loadDataEmployeeID = () => {
        const params = {
            FormID: "W09F2090",
            Mode: mode === "add" ? 0 : 1,
            Language: Config.language || "84",
            EmployeeID: dataForm?.Employee ?? "",
            TransID: "",
        };
        setLoading(true);
        props.w09f2091Actions.getDataEmployeeByID(params, (error, data) => {
            setLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if(data) {
                setAll(data);
            }
        });
    };

    const loadForm = () => {
        const params = {
            FormID: "W09F2090",
            Language: Config.language || 84,
            Mode: 1,
            TransID: TransID
        };
        setLoading(true);
        props.w09f2091Actions.getDataForm(params, (errors, data) => {
            setLoading(false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if (data && data[0]) {
                setAll(data[0])
            }
        })
    };

    const loadCoefficient = () => {
        setLoading(true);
        props.w09f2091Actions.getCoefficients((errors) => {
            setLoading(false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    const loadColumn = () => {
        setLoading(true);
        props.w09f2091Actions.getColumns((errors) => {
            setLoading(false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    const loadCboDivision = () => {
        setLoading(true);
        props.w09f2091Actions.getCboDivisions((errors) => {
            setLoading(false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    const loadCboDuty = () => {
        props.generalActions.getCboDuty2(error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    const loadCboDepartment = () => {
        const params = {
            DivisionID: dataForm?.DivisionID ?? ""
        };
        setLoading(true);
        props.w09f2091Actions.getCboDepartments(params, (errors, data) => {
            setLoading(false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            setDataCboDepartments(data || [])
        });
    };

    const loadAttachments = () => {
        const param = {
            KeyID: TransID || "",
            TableName: "D09T2090"
        };
        setLoading(true);
        props.generalActions.getAttachmentsByTransID(param, (err, data) => {
            setLoading(false);
            if (err) {
                Config.popup.show('ERROR', err);
                return false;
            }
            if (data && data.length > 0) {
                setDataOldAttachments(data);
            }
        });
    };

    useEffect(() => {
        if (mode === "add") {
            loadCboDivision();
            loadCboEmployees();
            loadCoefficient();
            loadColumn();
            loadCboDuty();
        }
        if (mode === "edit") {
            loadCboDivision();
            loadCoefficient();
            loadColumn();
            loadCboDuty();
            loadAttachments();
            loadForm();
        }
        if (mode === "view") {
            setIsModeView(true);
            loadCboDivision();
            loadCoefficient();
            loadColumn();
            loadCboDuty();
            loadForm();
            loadAttachments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.mode]);

    useEffect(() => {
        if(dataForm?.Employee && mode === "add") {
            loadDataEmployeeID();
        }
        // xet lai combo ko load api loadCboEmployees khi mode edit || view
        if(dataForm?.Employee && (mode === "edit" || mode === "view")) {
            const user = Config.getUser({EmployeeID: dataForm?.Employee ?? ""});
            setDataCboEmployees({
                rows: user ? [user] : null,
                total: 1
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm?.Employee]);

    useEffect(() => {
        if(dataForm?.DivisionID) {
            loadCboDepartment()
        }
        if(isChanged.current && dataForm?.DivisionID === "") {
            setData({DepartmentID: "", DutyID: ""});
            setDataCboDepartments([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm?.DivisionID]);

    const setAll = (data = {}) => {
        const dtBase = getDataByName(data,"BASE");
        const dtNewBase = getDataByName(data,"NewBASE");
        const dtCE = getDataByName(data,"CE");
        const dtNewCE = getDataByName(data,"NewCE");
        setDataBase({...dataBase,...dtBase});
        setDataNewBase({...dataNewBase,...dtNewBase});
        setDataCE({...dataCE,...dtCE});
        setDataNewCE({...dataNewCE,...dtNewCE});
        setData({
            TransID: data?.TransID ?? "",
            Employee: data?.EmployeeID ?? "",
            Validdate: data?.Validdate ?? "",
            DutyID: data?.NewDutyID ?? "",
            DepartmentID: data?.NewDepartmentID ?? "",
            DivisionID: data?.NewDivisionID ?? "",
            Note: data?.Note ?? "",
        });
        setIsSalaryAdjustment(!!data?.IsSalaryAdjustment);
    };

    const getDataByName = (data = {}, nameField = "") => {
        const dt = {};
        for(let [key, val] of Object.entries(data)) {
            let num = key.slice(-2);
            let name = `${nameField}${num}`;
            if(key === name) {
                dt[key] = val;
            }
        }
        return dt;
    };

    const setStateErrorText = (objValue) => {
        if (objValue) {
            setErrorHelperText({
                ...errorHelperText,
                ...objValue
            });
            return Object.keys(objValue).length !== 0;
        } else {
            setErrorHelperText({});
            return false;
        }
    };

    const setErrorHelperText = (obj) => {
        _setErrorHelperText({...errorHelperText, ...obj});
    };

    const setData = (obj) => {
        setDataForm({...dataForm, ...obj});
    };

    const onChangeAttachments = (e) => {
        attachments.current = e.allNewAttached ? e.allNewAttached : [];
        if (e.removedAttached && e.removedAttached.length > 0) {
            deletedFile.current = [...e.removedAttached];
            const _arrRemove = deletedFile.current.map((d) => d.AttachmentID);
            const dataNew = [...dataOldAttachments].filter(att=>_arrRemove.indexOf(att.AttachmentID) < 0);
            setDataOldAttachments(dataNew);
        }
    };

    const _getAttachments = (file) => {
        const dataFile = file?.data?.paths || [];
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileName ? att.fileName.split('.').pop() : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }
        return arrAttachment;
    };

    const _uploadFile = (files, isAsync, cb) => {
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    const _removeCDN = () => {
        if (deletedFile.current && deletedFile.current.length > 0) {
            deletedFile.current.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1]
                    };
                    CDN.removeFile(params);
                }
            });
            deletedFile.current = [];
        }
    };

    const setFieldName = (data, fieldName) => {
        const name = fieldName === "NewBASE" ? "ProBaseSalary" : "ProCoefficient";
        const dt = {};
        for(let [key,value] of Object.entries(data)) {
            let num = key.slice(-2);
            if(key.includes(fieldName)) {
                dt[`${name}${num}`] = value || 0;
            }
        }
        return dt;
    };

    const handleChangeBase = (key,e) => {
        if(!key) return null;
        const value = e?.value ?? e?.target?.value ?? null;
        setDataNewBase({...dataNewBase,[key]: value});
    };

    const handleChangeCE = (key, e) => {
        if(!key) return null;
        const value = e?.value ?? e?.target?.value ?? null;
        setDataNewCE({...dataNewCE, [key]: value});
    };

    const handleChange = (key, e) => {
        if (!key) return false;
        if(!isChanged.current) {
            isChanged.current = true;
        }
        const value = e?.value ?? e?.target?.value ?? "";
        if (errorHelperText[key] && value) setErrorHelperText({[key]: ""});
        switch (key) {
            case "Employee":
                setData({Employee: value});
                break;
            case "Validdate":
                setData({Validdate: value});
                break;
            case "DutyID":
                setData({DutyID: value});
                break;
            case "DepartmentID":
                setData({DepartmentID: value});
                break;
            case "DivisionID":
                setData({DivisionID: value});
                break;
            case "Note":
                setData({Note: value});
                break;
            default:
                break;
        }
    };

    const onSave = async () => {
        const validateRules = [
            {
                name: "Employee",
                rules: ["isRequired"],
                value: dataForm.Employee || ""
            },
            {
                name: "Validdate",
                rules: ["isRequired"],
                value: dataForm.Validdate || ""
            },
            {
                name: "DutyID",
                rules: ["isRequired"],
                value: dataForm.DutyID || ""
            },
            {
                name: "DepartmentID",
                rules: ["isRequired"],
                value: dataForm.DepartmentID || ""
            },
            {
                name: "DivisionID",
                rules: ["isRequired"],
                value: dataForm.DivisionID || ""
            },
        ];

        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            setStateErrorText(validateForm);
            if (setStateErrorText(validateForm)) return false;
        }
        const modeSave = mode === "edit" ? "update" : "save";
        const dataResUploadFile = await _uploadFile(attachments.current, true);
        const arrAttachments = _getAttachments(dataResUploadFile);
        const proBaseSalary = setFieldName(dataNewBase, "NewBASE");
        const proCoefficient = setFieldName(dataNewCE, "NewCE");
        let params = {
            FormID: "W09F2090",
            Language: Config.language || 84,
            IsAdjustSalary: +isSalaryAdjustment ?? 0,
            EmployeeID: dataForm?.Employee ?? "",
            NewDivisionID: dataForm?.DivisionID ?? "",
            NewDepartmentID: dataForm?.DepartmentID ?? "",
            NewDutyID: dataForm?.DutyID ?? "",
            Validdate: moment(dataForm?.Validdate).isValid() ?
                moment(dataForm?.Validdate).format("YYYY-MM-DD") : null,
            IsSalaryAdjustment: +isSalaryAdjustment ?? 0,
            Note: dataForm?.Note ?? "",
            arrAttachment: JSON.stringify(arrAttachments),
            ...proBaseSalary,
            ...proCoefficient
        };

        if(mode === "edit") {
            params = {...params, TransID: dataForm?.TransID ?? ""}
        }

        props.w09f2091Actions[modeSave](params, (errors, data) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if (data.Status === 1) {
                let message = data.Message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
            }
            if (data.Status === 0) {
                Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                _removeCDN();
                onClosePopup(true);
            }
        })

    };

    const onClosePopup = (isReload) => {
        if (onClose) onClose(isReload);
    };

    const errorEmployee = !!errorHelperText['Employee'] && errorHelperText['Employee'].length > 0;
    const errorValiddate = !!errorHelperText['Validdate'] && errorHelperText['Validdate'].length > 0;
    const errorDivisionID = !!errorHelperText['DivisionID'] && errorHelperText['DivisionID'].length > 0;
    const errorDepartmentID = !!errorHelperText['DepartmentID'] && errorHelperText['DepartmentID'].length > 0;
    const errorDutyID = !!errorHelperText['DutyID'] && errorHelperText['DutyID'].length > 0;
    return (
        <>
            <Modal
                onClose={() => onClosePopup()}
                open={open}
                pressESCToClose={false}
                moveable={false}
                dragAnyWhere={false}
                moveOutScreen={false}
                width={"80%"}
            >
                <ModalHeader title={Config.lang("Lap_de_xuat_kiem_nhiem")}/>
                <ModalBody>
                    <FormGroup>
                        <Row>
                            <Col xs={12} sm={12} md={4}>
                                <Dropdown
                                    clearAble
                                    required
                                    readOnly={mode === "edit"}
                                    error={errorEmployee ? errorHelperText["Employee"] : ""}
                                    dataSource={dataCboEmployees?.rows || []}
                                    total={dataCboEmployees?.total ?? 0}
                                    skip={filterCboEmployees.current.skip}
                                    limit={filterCboEmployees.current.limit}
                                    value={dataForm?.Employee}
                                    disabled={isModeView}
                                    loading={loading}
                                    iconExpr={"UserPictureURL"}
                                    viewType={"underlined"}
                                    valueExpr={"EmployeeID"}
                                    keyExpr={"EmployeeName"}
                                    searchDelayTime={1000}
                                    label={Config.lang("Nhan_vien")}
                                    placeholder={Config.lang("Chon")}
                                    displayExpr={"{EmployeeID} - {EmployeeName}"}
                                    onChange={(e) => handleChange("Employee", e)}
                                    onInput={(e) => {
                                        filterCboEmployees.current.strSearch = e.target.value;
                                        filterCboEmployees.current.skip = 0;
                                        loadCboEmployees(true);
                                    }}
                                    onLoadMore={(e) => {
                                        filterCboEmployees.current.skip = e.skip;
                                        filterCboEmployees.current.limit = e.limit;
                                        loadCboEmployees();
                                    }}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={4}>
                                <DatePicker
                                    error={errorValiddate ? errorHelperText["Validdate"] : ""}
                                    label={Config.lang('Ngay_hieu_luc')}
                                    required
                                    clearAble
                                    disabled={loading || isModeView}
                                    viewType={"underlined"}
                                    actionIconAt={'end'}
                                    placeholder={'DD/MM/YYYY'}
                                    displayFormat={'DD/MM/YYYY'}
                                    value={dataForm?.Validdate}
                                    onChange={(e) => handleChange('Validdate', e)}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col xs={12} sm={12} md={4}>
                                <Dropdown
                                    clearAble
                                    required
                                    error={errorDivisionID ? errorHelperText["DivisionID"] : ""}
                                    viewType={"underlined"}
                                    valueExpr={"DivisionID"}
                                    displayExpr={"DivisionName"}
                                    label={Config.lang("Don_vi_moi")}
                                    onChange={e => handleChange("DivisionID", e)}
                                    dataSource={getCboDivisions}
                                    disabled={loading || isModeView}
                                    value={dataForm?.DivisionID}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={4}>
                                <Dropdown
                                    clearAble
                                    required
                                    error={errorDepartmentID ? errorHelperText["DepartmentID"] : ""}
                                    viewType={"underlined"}
                                    valueExpr={"DepartmentID"}
                                    displayExpr={"DepartmentName"}
                                    label={Config.lang("Phong_ban_moi")}
                                    onChange={e => handleChange("DepartmentID", e)}
                                    dataSource={dataCboDepartments}
                                    disabled={loading || isModeView}
                                    value={dataForm?.DepartmentID}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={4}>
                                <Dropdown
                                    clearAble
                                    required
                                    error={errorDutyID ? errorHelperText["DutyID"] : ""}
                                    viewType={"underlined"}
                                    valueExpr={"DutyID"}
                                    displayExpr={"DutyName"}
                                    label={Config.lang("Chuc_danh_kiem_nhiem")}
                                    placeholder={Config.lang("Chon")}
                                    onChange={e => handleChange("DutyID", e)}
                                    dataSource={getCboDuty}
                                    disabled={loading || isModeView}
                                    value={dataForm?.DutyID}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col xs={12}>
                                <TextInput
                                    maxRows={3}
                                    multiline
                                    label={Config.lang("Ghi_chu")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.Note}
                                    disabled={loading || isModeView}
                                    onChange={(e) => handleChange("Note", e)}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col xs={12}>
                                <Checkbox
                                    className={"mgr15"}
                                    disabled={loading || isModeView}
                                    label={Config.lang("Dieu_chinh_luong")}
                                    onChange={e => setIsSalaryAdjustment(e.target.checked)}
                                    checked={isSalaryAdjustment}/>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        {isSalaryAdjustment ?
                            <Row>
                                <Col xs={12}>
                                    <Row>
                                        <Col xs={12} sm={12} md={6}>
                                            {getColumns.map((item, idx) => {
                                                const num = item?.FieldName?.slice(-2) ?? "";
                                                const oldValue = dataBase[item?.FieldName] ?? null;
                                                const newValue = dataNewBase[`New${item?.FieldName}`] ?? null;
                                                return (
                                                    <React.Fragment key={idx}>
                                                        <Row>
                                                            <Col xs={4}>
                                                                {idx === 0 ?
                                                                    <Typography
                                                                        uppercase type={"h5"}>{Config.lang("Muc_luong")}</Typography>
                                                                    : ""
                                                                }
                                                                <Typography className={classes.customTypography}
                                                                            uppercase type={"p1"}>{item?.CaptionName ?? ""}</Typography>
                                                            </Col>
                                                            <Col xs={4}>
                                                                {idx === 0 ?
                                                                    <Typography className={classes.customTitle} uppercase type={"h5"}>{Config.lang("Cu")}</Typography>
                                                                    : ""
                                                                }
                                                                <MoneyInput value={oldValue}  disabled={isModeView} thousandSeparator={","} readOnly/>
                                                            </Col>

                                                            <Col xs={4}>
                                                                {idx === 0 ?
                                                                    <Typography
                                                                        uppercase className={classes.customTitle} type={"h5"}>{Config.lang("Moi")}</Typography>
                                                                    : ""
                                                                }
                                                                <MoneyInput
                                                                    value={newValue}
                                                                    disabled={loading || isModeView}
                                                                    decimalDigit={item.Decimals}
                                                                    thousandSeparator={","}
                                                                    disabledNegative
                                                                    onChange={e => handleChangeBase(`NewBASE${num}`,e)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Col>
                                        <Col xs={12} sm={12} md={6}>
                                            {getCoefficients.map((item, idx) => {
                                                const num = item?.FieldName?.slice(-2) ?? "";
                                                const oldValue = dataCE[item?.FieldName] ?? null;
                                                const newValue = dataNewCE[`New${item?.FieldName}`] ?? null;
                                                return (
                                                    <React.Fragment key={idx}>
                                                        <Row>
                                                            <Col xs={4}>
                                                                {idx === 0 ?
                                                                    <Typography
                                                                        uppercase type={"h5"}>{Config.lang("He_so")}</Typography>
                                                                    : ""
                                                                }
                                                                <Typography
                                                                    className={classes.customTypography}
                                                                    uppercase type={"p1"}>{item?.CaptionName ?? ""}</Typography>
                                                            </Col>
                                                            <Col xs={4}>
                                                                {idx === 0 ?
                                                                    <Typography
                                                                        uppercase type={"h5"}>{Config.lang("Cu")}</Typography>
                                                                    : ""
                                                                }
                                                                <MoneyInput value={oldValue} thousandSeparator={","} disabled={isModeView} readOnly/>
                                                            </Col>
                                                            <Col xs={4}>
                                                                {idx === 0 ?
                                                                    <Typography
                                                                        uppercase type={"h5"}>{Config.lang("Moi")}</Typography>
                                                                    : ""
                                                                }
                                                                <MoneyInput
                                                                    value={newValue}
                                                                    disabled={loading || isModeView}
                                                                    decimalDigit={item.Decimals}
                                                                    thousandSeparator={","}
                                                                    disabledNegative
                                                                    onChange={e => handleChangeCE(`NewCE${num}`,e)}/>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )
                                            })}

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            : ""}
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col xs={12}>
                                <Attachment
                                    label={Config.lang("Dinh_kem")}
                                    data={dataOldAttachments}
                                    disabled={loading || iPermission <= 1 || isModeView || (mode === "edit" && AppStatusID)}
                                    onChange={(e) => {
                                        onChangeAttachments(e)
                                    }}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        text={Config.lang("Luu")}
                        color={"info"}
                        startIcon={"save"}
                        viewType={"filled"}
                        disabled={loading || isModeView || iPermission <= 1}
                        onClick={onSave}
                    />
                </ModalFooter>
            </Modal>
        </>
    );
};

W09F2091.propTypes = {
    data: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    iPermission: PropTypes.number,
};

W09F2091.defaultProps = {
    data: {},
    open: false,
    onClose: null,
    iPermission: 0,
};

export default compose(
    connect((state) => ({
            getCboDuty: state.general.getCboDuty2,
            getCoefficients: state.W09F2091.getCoefficients,
            getColumns: state.W09F2091.getColumns,
            getCboDivisions: state.W09F2091.getCboDivisions,
        }),
        (dispatch) => ({
            w09f2091Actions: bindActionCreators(W09F2091Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    ),
)(W09F2091);
