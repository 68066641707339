/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 2/6/2020
 * @Example
 */

import React from "react";
import {bindActionCreators, compose} from "redux";
import {withStyles, Divider} from "@material-ui/core";
import {connect} from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import ButtonGeneral from "../../../common/button/button-general";
import Modal from "../../../common/modal/modal";
import GridContainer from "../../../grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import {Image, FormGroup, Row, Col} from "react-bootstrap";
import Filter from "../../../filter/filter";
import {Combo} from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import moment from "moment";
import {browserHistory} from "react-router";
import W09F2070Popup from "./W09F2070Popup";
import {ButtonIcon} from "diginet-core-ui/components";
import _ from "lodash";

import * as W09F2070Actions from "../../../../redux/W0X/W09F2070/W09F2070_actions";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import Status from "../../../common/status/status";

const styles = theme => ({
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: "50%",
        overflow: "hidden",
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain"
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB"
    },
    dateInfo: {
        display: "flex",
        alignItems: "center"
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757"
    },
    divDateText: {
        fontSize: "14px",
        fontWeight: 400,
        color: theme.palette.text.subtext
    },
    divider:{
        width: 2,
        height: 11,
        margin: '0 5px'
    }
});

class W09F2070 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            mode: "add",
            rowData: null,
            showW09F2070Popup: false,
            dataCboEmployees: {
                rows: [],
                total: 0
            },
            dataCboProjects: {
                rows: [],
                total: 0
            },
            loading: {
                cboEmployees: false
            },
        };
        this.loading = {
            cboEmployees: false
        };
        this.filter = {
            ProDateFrom: "",
            ProDateTo: "",
            Employee: "%",
            Project: "%",
            AppStatusID: "%",
            skip: 0,
            limit: 10
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.filterCboProjects = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F2070", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    onSearch = () => {
        this.loadDataGrid();
    };

    onChangePageProfile = data => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: {EmployeeID: data.EmployeeID}
        });
    };

    loadRewardCaption = () => {
        this.props.w09f2070Actions.loadRewardCaption({Languaghe: Config.language || "84"}, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    renderEmpProfile = e => {
        const {classes} = this.props;
        const {data} = e.row;
        const date = moment(data.ProposalDate).format("LT, DD/MM/YYYY");
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} onClick={() => this.onChangePageProfile(data)}/>
                <div className={"w75f2000_info"}>
                    <div className={classes.divText}>
                        <UserName data={data}/>
                    </div>
                    <div className={classes.divText}>
                        {data.DepartmentName || ""}
                    </div>
                    <div className={classes.dateInfo}>
                        <Image
                            src={require("../../../../assets/images/icon-calendar.svg")}
                        />
                        <div style={{paddingLeft: "8px"}} className={"date-text-info"}>
                            {data.ProposalDate && date}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderInfo = e => {
        const {data} = e.row;
        //const date = moment(data.ApprovalDate).format("DD/MM/YYYY");
        const {classes} = this.props;
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                <div>{data.ProNotice || ""}</div>
                <div>
                    {Config.lang("DHR_Du_an_cong_trinh")}:{" "}
                    {data.ProjectName || ""}
                </div>
                {data.ProposerName && (
                    <div>
                        {Config.lang("DHR_Nguoi_lap")}: {data.ProposerName || ""}
                    </div>
                )}
                {data.ApproverName && (
                    <div>
                        {Config.lang("DHR_Nguoi_duyet")}: {data.ApproverName || ""} -{" "}
                        {data.DepartmentID || ""} - {data.ApprovalDate || ""}
                    </div>
                )}
                {
                    data.AppStatusID > 0 &&
                    <div>
                        {Config.lang("DHR_Ghi_chu_duyet")}: {data.ApprovalNotes || ""}
                    </div>
                }
            </div>
        );
    };

    renderStatus = e => {
        const {data} = e.row;

        return (
            <Status data={data} />
        );
    };

    onAdd = () => {
        this.setState({
            mode: "add",
            rowData: null,
            showW09F2070Popup: true
        });
    };

    onView = e => {
        const {data} = e.row;
        this.setState({
            mode: "view",
            rowData: data,
            showW09F2070Popup: true
        });
    };

    onDelete = data => {
        const {ProTransID} = data;

        const param = {
            ProTransID
        };

        Config.popup.show(
            "YES_NO",
            Config.lang("DHR_Ban_co_chac_muon_xoa?"),
            () => {
                this.props.w09f2070Actions.deleteDataGrid(param, error => {
                    if (error) {
                        Config.popup.show("INFO", Config.lang("DHR_Loi_chua_xac_dinh"));
                        return false;
                    } else {
                        Config.notify.show(
                            "success",
                            Config.lang("DHR_Xoa_thanh_cong"),
                            3000
                        );
                        this.loadDataGrid();
                    }
                });
            }
        );
    };

    renderAction = e => {
        const {data} = e.row;
        const {classes} = this.props;
        return (
            <div className={"display_row align-center"}>
                <ButtonIcon
                    circular={true}
                    size={"small"}
                    viewType={"text"}
                    name={"View"}
                    onClick={() => this.onView(e)}
                />
                <Divider orientation="vertical" className={classes.divider}/>
                <ButtonIcon
                    circular={true}
                    size={"small"}
                    viewType={"text"}
                    name={"Delete"}
                    disabled={Number(data?.OnlyWatch) === 1 || Number(data?.AppStatusID) !== 0}
                    onClick={() => this.onDelete(data)}
                />
            </div>
        );
    };

    filterChange = (key, data) => {
        const {value} = data;
        if (!key) return false;
        switch (key) {
            case "DateFrom":
                this.filter.ProDateFrom = value;
                break;
            case "DateTo":
                this.filter.ProDateTo = value;
                break;
            case "EmployeeID":
                this.filter.Employee = value ?? "%";
                break;
            case "ProjectID":
                this.filter.Project = value ?? "%";
                break;
            case "AppStatusID":
                this.filter.AppStatusID =  value ?? "%";
                break;
            default:
                break;
        }
    };

    renderFilter = () => {
        const {getCboAppStatus, classes} = this.props;
        const {ProDateTo, ProDateFrom, Project, AppStatusID, Employee} = this.filter;
        const {dataCboEmployees, dataCboProjects, loading} = this.state;
        return (
            <Filter
                placeholder={Config.lang("DHR_Nhap_tu_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <div className={classes.divText}>
                                                {Config.lang("DHR_Tu")}
                                            </div>
                                            <DateBoxPicker
                                                dateBoxProps={{max: ProDateTo}}
                                                style={{marginLeft: "5px"}}
                                                onValueChanged={e => this.filterChange("DateFrom", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={ProDateFrom}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <div className={classes.divText}>
                                                {Config.lang("DHR_Den").toLowerCase()}
                                            </div>
                                            <DateBoxPicker
                                                dateBoxProps={{min: ProDateFrom}}
                                                style={{marginLeft: "5px"}}
                                                onValueChanged={e => this.filterChange("DateTo", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={ProDateTo && ProDateTo}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("DHR_Trang_thai_duyet")}
                                            dataSource={getCboAppStatus}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"AppStatusID"}
                                            onValueChanged={e => this.filterChange("AppStatusID", e)}
                                            showClearButton={true}
                                            value={AppStatusID === "%" ? "" : AppStatusID}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={'EmployeeID'}
                                            valueExpr={'EmployeeID'}
                                            value={Employee === "%" ? null : Employee}
                                            loading={loading.cboEmployees}
                                            stylingMode={'outlined'}
                                            label={Config.lang('DHR_Nhan_vien')}
                                            itemRender={(e) => {
                                                const {data} = e;
                                                if (!data) return null;
                                                return data.EmployeeID + ' - ' + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={e => this.filterChange("EmployeeID", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboProjects}
                                            skip={this.filterCboProjects.skip}
                                            limit={this.filterCboProjects.limit}
                                            displayExpr={'ProjectName'}
                                            keyExpr={'ProjectID'}
                                            valueExpr={'ProjectID'}
                                            value={Project === "%" ? null : Project}
                                            loading={loading.cboProjects}
                                            stylingMode={'outlined'}
                                            label={Config.lang('DHR_Du_an')}
                                            itemRender={(e) => {
                                                const {data} = e;
                                                if (!data) return null;
                                                return data.ProjectID + ' - ' + data.ProjectName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={e => this.filterChange("ProjectID", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboProjects.strSearch = e.target.value;
                                                this.filterCboProjects.skip = 0;
                                                this.loadCboProjects(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboProjects.skip = e.skip;
                                                this.filterCboProjects.limit = e.limit;
                                                this.loadCboProjects();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: "38px",
                                    paddingBottom: "40px"
                                }}
                            >
                                <ButtonGeneral
                                    name={Config.lang("DHR_Tim_kiem")}
                                    typeButton={"search"}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={this.onSearch}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    componentDidMount = async() => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadRewardCaption();
        this.loadCboEmployees();
        this.loadCboProjects();
        this.loadDataGrid();
        this.loadCboAppStatus();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {getDataGrid} = this.props;
        Config.callChildForm({
            FormID: "W09F2070",
            // ID: voucher_id,
            keyExpr: "ProTransID",
            data: getDataGrid && getDataGrid.rows ? getDataGrid.rows : [],
            onLoad: (params) => this.loadDataGrid(params && params.ID),
            onAction: (e, data) => this.onView({ row: { data: data } })
        }, this.props);
    }

    loadCboEmployees = (isReset) => {
        const param = {
            Type: "EmployeeID",
            FormID: "W09F2070",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.loading.cboEmployees = true;
        this.setState({loading: this.loading});
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.loading.cboEmployees = false;
            this.setState({loading: this.loading});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboProjects = (isReset) => {
        const param = {
            HostID: "",
            FormID: "W09F2070",
            Language: Config.language || "84",
            skip: this.filterCboProjects.skip,
            limit: this.filterCboProjects.limit,
            search: this.filterCboProjects.strSearch
        };
        this.loading.cboProjects = true;
        this.setState({loading: this.loading});
        this.props.generalActions.getCboProjects(param, (error, data) => {
            this.loading.cboProjects = false;
            this.setState({loading: this.loading});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboProjects} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadDataGrid = (VoucherID) => {
        const {voucher_id} = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const {Employee, Project, AppStatusID, ProDateFrom, ProDateTo, skip, limit} = this.filter;
        const param = {
            TransID: VoucherID || "",
            FormID: "W09F2070",
            Language: Config.language || 84,
            ProDateFrom: ProDateFrom,
            ProDateTo: ProDateTo,
            EmployeeID: Employee && Employee.EmployeeID ? Employee.EmployeeID : "%" ,
            ProjectID: Project && Project.ProjectID ? Project.ProjectID : "%",
            AppStatusID: _.toString(AppStatusID),
            skip: skip,
            limit: limit
        };
        this.setState({gridLoading: true});
        this.props.w09f2070Actions.loadDataGrid(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({gridLoading: false});
        });
    };

    loadCboAppStatus = () => {
        const param = {
            FormID: "W09F2070",
            Language: Config.language || 84
        };

        this.props.w09f2070Actions.loadAppStatus(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    onChangePage = page => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    };

    onChangePerPage = perPage => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadDataGrid();
    };

    onCloseModal = (isSaved = false) => {
        if(isSaved) this.loadDataGrid();
        this.setState({
            showW09F2070Popup: false
        });
    };

    render() {
        const {getDataGrid} = this.props;
        const {iPermission, gridLoading, showW09F2070Popup, mode, rowData} = this.state;
        if (!iPermission) return null;
        const {skip, limit} = this.filter;
        return (
            <>
                <Modal
                    open={showW09F2070Popup}
                    maxWidth={"md"}
                    fullWidth={true}
                    onClose={() => this.onCloseModal(false)}
                    title={Config.lang("DHR_Lap_de_xuat_ky_luat")}
                >
                    <W09F2070Popup
                        mode={mode}
                        data={rowData}
                        onClose={this.onCloseModal}
                    />
                </Modal>
                <div className={"hidden"}>{this.renderFilter()}</div>
                <ActionToolbar title={Config.lang("DHR_De_xuat_ky_luat")}>
                    <ButtonGeneral
                        name={Config.lang("DHR_Them")}
                        typeButton={"add"}
                        style={{textTransform: "uppercase"}}
                        size={"large"}
                        onClick={this.onAdd}
                    />
                </ActionToolbar>
                <GridContainer
                    style={{border: "none"}}
                    dataSource={getDataGrid && getDataGrid.rows}
                    showRowLines={true}
                    showBorders={false}
                    showColumnLines={false}
                    showColumnHeaders={false}
                    noDataText={Config.lang("DHR_No_data")}
                    onCellClick={this.onDetail}
                    loading={gridLoading}
                    hoverStateEnabled={true}
                    totalItems={getDataGrid && Number(getDataGrid.total)}
                    itemPerPage={limit}
                    skipPerPage={skip}
                    typePaging={"remote"}
                    height={Config.getHeightGrid()}
                    onChangePage={this.onChangePage}
                    onChangePerPage={this.onChangePerPage}
                >
                    <Column cellRender={this.renderEmpProfile} width={250}/>
                    <Column cellRender={this.renderInfo}/>
                    <Column cellRender={this.renderStatus} alignment={"center"} width={200}/>
                    <Column cellRender={this.renderAction} width={200}/>
                </GridContainer>
            </>
        );
    }
}

export default compose(
    connect(
        state => ({
            getCboAppStatus: state.W09F2070.getCboAppStatus,
            getDataGrid: state.W09F2070.getDataGrid
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w09f2070Actions: bindActionCreators(W09F2070Actions, dispatch)
        })
    ),
    withStyles(styles)
)(W09F2070);
