/**
 * @copyright 2021 @ DigiNet
 * @author XUANLOC
 * @create 28/9/2021
 * @Example
 */

import { makeStyles } from '@material-ui/styles';
import { Button, Checkbox, DatePicker, DateRangePicker, Dropdown, TextInput, Tooltip } from 'diginet-core-ui/components';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Config from '../../../../config';
import * as approvalActions from '../../../../redux/approvals/approvals_actions';
import * as generalActions from '../../../../redux/general/general_actions';
import * as W09F2092Actions from '../../../../redux/W0X/W09F2092/W09F2092_actions';
import Approvals from '../../../approvals/approvals';
import ActionToolbar from '../../../common/toolbar/action-toolbar';
import Filter from '../../../filter/filter';
import GridContainer from '../../../grid-container/grid-container';
import { Column } from 'devextreme-react/data-grid';

const useStyles = makeStyles((theme) => ({
    divFormInfo: {
        display: 'grid',
        gap: 10,
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    },
    divDynamicGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(330px, 1fr))',
        gap: 10,
        marginTop: 20,
    },
}));

const W09F2092 = (props) => {
    const { location } = props;

    const classes = useStyles();

    const [iPermission, setIPermission] = useState(0);
    const [loadingCboEmployees, setLoadingCboEmployees] = useState(false);
    const [dataCboEmployees, setDataCboEmployees] = useState({
        rows: [],
        total: 0,
    });
    const [filter, setFilter] = useState(() => ({
        DutyID: '',
        ApprovalStatus: null,
        EmployeeID: '',
        DateFrom: null,
        DateTo: null,
    }));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState([]);
    const [dynamicColumn1, setDynamicColumn1] = useState([]);
    const [dynamicColumn2, setDynamicColumn2] = useState([]);
    const [dataFilter, setDataFilter] = useState(() => {
        return {
            UserID: Config?.profile?.UserID ?? '',
            ApprovalStatus: filter?.ApprovalStatus ?? null,
            EmployeeID: filter?.EmployeeID ?? '',
            DutyID: filter?.DutyID ?? '',
            DateFrom: filter?.DateFrom ?? null,
            DateTo: filter?.DateTo ?? null,
        };
    });

    const toolFilter = useRef(null);
    const approvalRef = useRef(null);
    
    const paramFilterCboEmployees = {
        skip: 0,
        limit: 10,
        search: '',
    };

    const dispatch = useDispatch();
    const dataCboStatus = useSelector((state) => state.approvals.getCboStatus);
    const dataCboDuty = useSelector((state) => state.general.getCboDuty2);

    const loadPermission = () => {
        dispatch(
            generalActions.getPermission('W09F2092', (isPer) => {
                setIPermission(isPer);
            })
        );
    };

    const loadDynamicColumn = (type = 'SALBA') => {
        const params = {
            Type: type,
        };
        dispatch(W09F2092Actions.getDynamicColumn(params, (err, dt) => {
            if (err) {
                Config.popup.show('ERROR', err);
                return false;
            }
            if (dt) {
                switch (type) {
                    case 'SALBA':
                        setDynamicColumn1(dt);
                        break;
                    case 'SALCE':
                        setDynamicColumn2(dt);
                        break;
                    default:
                        break;
                }
            }
        }));
    }

    useEffect(() => {
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!iPermission) return;
        loadDynamicColumn();
        loadDynamicColumn('SALCE');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iPermission]);

    useEffect(() => {
        const VoucherID = location?.state?.voucher_id ?? null;
        if (VoucherID) {
            loadFormInfo(VoucherID, (dt) => {
                dt.VoucherID = VoucherID;
                approvalRef.current && approvalRef.current.showPopup(dt);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const loadCboDuty = () => {
        dispatch(
            generalActions.getCboDuty2((error) => {
                if (error) {
                    Config.popup.show('ERROR', error);
                    return false;
                }
            })
        );
    };

    const loadCboStatus = () => {
        const params = {
            FormID: 'STANDARD',
            Language: Config.language || '84',
        };
        dispatch(
            approvalActions.getCboStatus(params, (error) => {
                if (error) {
                    Config.popup.show('ERROR', error);
                    return false;
                }
            })
        );
    };

    const loadCboEmployees = (isReset) => {
        const param = {
            Type: 'EmployeeID',
            FormID: 'W09F2092',
            Language: Config.language || '84',
            ...paramFilterCboEmployees,
        };
        setLoadingCboEmployees(true);
        dispatch(
            generalActions.getCboEmployees(param, (err, data) => {
                setLoadingCboEmployees(false);
                if (err) return Config.popup.show('ERROR', err);
                if (data) {
                    const rows = data?.rows ?? data;
                    const total = data?.total ?? data?.length;
                    setDataCboEmployees({
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total,
                    });
                }
            })
        );
    };

    const onLoadCboFilter = () => {
        loadCboDuty();
        loadCboStatus();
        loadCboEmployees();
    };

    const renderFilter = () => {
        return (
            <Filter
                isUseDDCore
                dropdownProps={{
                    ref: toolFilter,
                }}
                placeholder={Config.lang('Noi_dung_can_tim_kiem')}
                showHeader={false}
                openOnFieldClick={true}
                onOpenLoaded={onLoadCboFilter}
                renderFilter={() => {
                    return (
                        <>
                            <DateRangePicker
                                clearAble
                                controls
                                value={[filter?.DateFrom ?? null, filter?.DateTo ?? null]}
                                label={Config.lang('Ngay_hieu_luc')}
                                onChange={(e) => filterChange('Date', e)}
                                placeholder={`${Config.lang('Tu_ngay')} - ${Config.lang('Den_ngay')}`}
                                returnFormat={'YYYY-MM-DD'}
                                viewType={'outlined'}
                            />
                            <Dropdown
                                clearAble
                                valueExpr={'DutyID'}
                                keyExpr={'DutyName'}
                                displayExpr={'DutyName'}
                                placeholder={Config.lang('Chon')}
                                label={Config.lang('Chuc_danh_kiem_nhiem')}
                                onChange={(e) => filterChange('DutyID', e)}
                                dataSource={dataCboDuty}
                                value={filter?.DutyID ?? null}
                                viewType={'outlined'}
                            />
                            <Dropdown
                                clearAble
                                valueExpr={'ApprovalStatus'}
                                keyExpr={'AppStatusName'}
                                displayExpr={'AppStatusName'}
                                placeholder={Config.lang('Chon')}
                                label={Config.lang('Trang_thai_duyet')}
                                onChange={(e) => filterChange('ApprovalStatus', e)}
                                dataSource={dataCboStatus}
                                value={filter?.ApprovalStatus ?? null}
                                viewType={'outlined'}
                            />
                            <Dropdown
                                allowSearch
                                clearAble
                                dataSource={dataCboEmployees.rows}
                                total={dataCboEmployees.total}
                                skip={paramFilterCboEmployees.skip}
                                limit={paramFilterCboEmployees.limit}
                                displayExpr={'{EmployeeID} - {EmployeeName}'}
                                keyExpr={'EmployeeName'}
                                valueExpr={'EmployeeID'}
                                placeholder={Config.lang('Chon')}
                                label={Config.lang('Nhan_vien')}
                                onChange={(e) => filterChange('EmployeeID', e)}
                                value={filter?.EmployeeID ?? null}
                                viewType={'outlined'}
                                loading={loadingCboEmployees}
                                onInput={(e) => {
                                    paramFilterCboEmployees.search = e?.target?.value ?? '';
                                    paramFilterCboEmployees.skip = 0;
                                    loadCboEmployees(true);
                                }}
                                onLoadMore={(e) => {
                                    paramFilterCboEmployees.skip = e?.skip ?? 0;
                                    paramFilterCboEmployees.limit = e?.limit ?? 10;
                                    loadCboEmployees();
                                }}
                            />
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    text={Config.lang('Tim_kiem')}
                                    viewType='outlined'
                                    color={'primary'}
                                    onClick={onSearch}
                                    startIcon={'Search'}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    const filterChange = (key, data) => {
        switch (key) {
            case 'ApprovalStatus':
                setFilter({ ...filter, [key]: data?.value ?? null });
                break;
            case 'DutyID':
            case 'EmployeeID':
                setFilter({ ...filter, [key]: data?.value ?? '' });
                break;
            case 'Date':
                setFilter({ ...filter, DateFrom: data?.value?.[0] ?? null, DateTo: data?.value?.[1] ?? null });
                break;
            default:
                break;
        }
    };

    const onSearch = () => {
        setDataFilter({ ...dataFilter, ...filter });
        if (toolFilter.current) toolFilter.current.close();
    };

    const onSelectionChanged = (e) => {
        if (!e) return false;
        loadFormInfo(e.VoucherID);
    };

    const loadFormInfo = (voucherID, cb) => {
        const params = {
            DivisionID: Config.getDivisionID() ?? '',
            FormID: 'W09F2092',
            UserID: Config?.profile?.UserID ?? '',
            Language: Config?.language ?? '84',
            VoucherID: voucherID ?? '',
        };
        setLoading(true);
        dispatch(
            approvalActions.getFormInfo(params, (err, dt) => {
                setLoading(false);
                if (err) {
                    const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                    return Config.popup.show('ERROR', msg);
                }
                if (dt) {
                    setDataForm(dt);
                    cb && cb(dt);
                }
            })
        );
    };

    const renderDynamicColumn = (e, isNew = false) => {
        const { data = {} } = e;
        const value = dataForm?.[isNew ? 'New' + data?.FieldName : data?.FieldName] ?? 0;
        return value?.toLocaleString('en-US');
    };

    const renderFormInfo = (data) => {
        const user = Config.getUser({ EmployeeID: data?.EmployeeID ?? '' });
        return (
            <>
                <div className={classes.divFormInfo}>
                    <Dropdown
                        required
                        readOnly
                        label={Config.lang('Nhan_vien')}
                        displayExpr={'EmployeeName'}
                        keyExpr={'EmployeeName'}
                        valueExpr={'EmployeeID'}
                        iconExpr={'UserPictureURL'}
                        dataSource={[user]}
                        value={user?.EmployeeID ?? ''}
                    />
                    <DatePicker required readOnly label={Config.lang('Ngay_hieu_luc')} displayFormat={'DD/MM/YYYY'} value={data?.Validdate ?? null} />
                    <div></div>
                </div>
                <div className={classes.divFormInfo}>
                    <Tooltip title={data?.NewDivisionName ?? ''} style={{ width: 'auto' }} >
                        <Dropdown
                            required
                            readOnly
                            label={Config.lang('Don_vi_moi')}
                            dataSource={[data?.NewDivisionName ?? '']}
                            value={data?.NewDivisionName ?? ''}
                        />
                    </Tooltip>
                    <Tooltip title={data?.NewDepartmentName ?? ''} style={{ width: 'auto' }} >
                        <Dropdown
                            required
                            readOnly
                            label={Config.lang('Phong_ban_moi')}
                            dataSource={[data?.NewDepartmentName ?? '']}
                            value={data?.NewDepartmentName ?? ''}
                        />
                    </Tooltip>
                    <Tooltip title={data?.NewDutyName ?? ''} style={{ width: 'auto' }} >
                        <Dropdown
                            required
                            readOnly
                            label={Config.lang('Chuc_danh_kiem_nhiem')}
                            dataSource={[data?.NewDutyName ?? '']}
                            value={data?.NewDutyName ?? ''}
                        />
                    </Tooltip>
                </div>
                <Tooltip title={data?.Note ?? ''} style={{ width: 'auto' }} >
                    <TextInput readOnly label={Config.lang('Ghi_chu')} value={data?.Note ?? ''} />
                </Tooltip>
                <Checkbox readOnly checked={Boolean(data?.IsSalaryAdjustment ?? 0)} label={Config.lang('Dieu_chinh_luong')} color={'primary'} />
                {data?.IsSalaryAdjustment === 1 && 
                    (<div className={classes.divDynamicGrid}>
                        <GridContainer
                            showRowLines={true}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                            pagerFullScreen={false}
                            typePaging={'normal'}
                            noDataText={Config.lang('No_data')}
                            style={{ border: 'none' }}
                            height={200}
                            totalItems={dynamicColumn1?.length ?? 0}
                            dataSource={dynamicColumn1 ?? []}
                        >
                            <Column caption={Config.lang('Muc_luong')} dataField={'CaptionName'} />
                            <Column caption={Config.lang('Cu')} cellRender={(e) => renderDynamicColumn(e)} />
                            <Column caption={Config.lang('Moi')} cellRender={(e) => renderDynamicColumn(e, true)} />
                        </GridContainer>
                        <GridContainer
                            showRowLines={true}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                            pagerFullScreen={false}
                            typePaging={'normal'}
                            noDataText={Config.lang('No_data')}
                            style={{ border: 'none' }}
                            height={200}
                            totalItems={dynamicColumn2?.length ?? 0}
                            dataSource={dynamicColumn2 ?? []}
                        >
                            <Column caption={Config.lang('He_so')} dataField={'CaptionName'} />
                            <Column caption={Config.lang('Cu')} cellRender={(e) => renderDynamicColumn(e)} />
                            <Column caption={Config.lang('Moi')} cellRender={(e) => renderDynamicColumn(e, true)} />
                        </GridContainer>
                    </div>)
                }
            </>
        );
    };

    return (
        <>
            <ActionToolbar title={Config.lang('Duyet_de_xuat_kiem_nhiem')} />
            <Approvals
                ref={approvalRef}
                FormID={'W09F2092'}
                loading={loading}
                dataFilter={dataFilter}
                dataForm={dataForm}
                singleClick
                filterRender={renderFilter}
                formInfoRender={renderFormInfo}
                onSelectionChanged={onSelectionChanged}
            />
        </>
    );
};

export default W09F2092;
