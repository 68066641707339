/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 3/19/2020
 * @Example
 */

import React from "react";
import {FormGroup, Col, Row, Image} from "react-bootstrap";
import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Approvals from "../../../approvals/approvals";
import LabelText from "../../../common/label-text/label-text";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {Input, withStyles, FormControlLabel, Checkbox} from "@material-ui/core";
import InlineSVG from "react-inlinesvg";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W09F2081Actions from "../../../../redux/W0X/W09F2081/W09F2081_actions";
import * as W09F2150Actions  from "../../../../redux/W0X/W09F2150/W09F2150_actions";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {TextField} from "../../../common/form-material";
import NumberFormat from "react-number-format";
import {MoneyInput} from "diginet-core-ui/components";

const styles = {
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: '1px solid #ddd',
        overflow: 'hidden',
        marginRight: 8
    },
    imgAvatar: {
        width: '100%',
        maxWidth: '100%',
        objectFit: 'contain'
    },
    label: {
        fontSize: "1rem",
        fontWeight: 200,
        color:"#757575"
    },
    divText : {
        // fontSize: "1rem",
        fontWeight: 500,
        // color:"#575757"
    },
    expandPanel: {
        boxShadow: 'none'
    },
    expandSummary: {
        minHeight: '37px !important',
        padding: 0,
        '& .MuiExpansionPanelSummary-content': {
            margin: 0
        }
    },
    expandDetail: {
        display: 'block',
        padding: '10px 0'
    }
};

class W09F2081 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            dataForm: {},
            isExpanded: false,
            VoucherID: "",
            dataColumnSalary: [],
            loading: false
        }
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F2081", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    loadCaption = () => {
        this.props.w09F2150Actions.loadCaption({Language: Config.language || "84" },(error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadDynamicColumnSalary = () => {
        const params = {
               Language: Config.language || 84
        };
         this.props.w09F2081Actions.getDynamicColumnSalary(params,(error,data) => {
              if (error) {
                     let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                   Config.popup.show("ERROR", message);
                     return false;
                  }
               if(data) {
                     this.setState({dataColumnSalary : data});
                   }
            });
        };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return false;
        this.loadCaption();
        this.loadDynamicColumnSalary();
    };

    onSelectionChanged = (e) => {
        if (!e) return false;
        this.loadFormInfo(e.VoucherID);
    };

    onDrawerClosed = () => {
        this.setState({VoucherID: ""});
    };

    loadFormInfo = (VoucherID, cb) => {
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W09F2081",
            Language: Config.language || "84",
            VoucherID: VoucherID ? VoucherID : "",
        };
        this.setState({
            dataForm: [],
            loading: true
        });
        this.props.approvalActions.getFormInfo(params, (error, data) => {
            this.setState({loading: false})
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this.setState({
                    dataForm: data
                });
                cb && cb (data);
            }
        });
    };

    renderFormInfo = (data) => {
        const {classes, getCaption} = this.props;
        const {isExpanded, dataColumnSalary} = this.state;
        if (data && data.UserPictureURL && data.UserPictureURL.indexOf('http') < 0) {
            data.UserPictureURL = Config.getCDNPath() + data.UserPictureURL;
        }
        return (
            <>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Nhan_vien")}
                                value={() => {
                                    return (
                                        <div className={"display_row align-center pdb5"}
                                             style={{width: "100%", height: 37}}>
                                            <div className={classes.divAvatar}>
                                                {data && data.UserPictureURL ? (
                                                    <Image className={classes.imgAvatar} src={data.UserPictureURL}/>
                                                ) : (
                                                    <InlineSVG className={classes.imgAvatar}
                                                               src={require('../../../../assets/images/general/user_default.svg')}/>
                                                )}
                                            </div>
                                            <Input
                                                color={"primary"}
                                                readOnly={true}
                                                value={Config.helpers.getObjectValue(data, "EmployeeName", "")}
                                                disableUnderline={true}
                                            />
                                        </div>
                                    );
                                }}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(data.IsSalaryAdjustment)}
                                        color="primary"
                                    />
                                }
                                label={Config.lang("DHR_De_xuat_dieu_chinh_luong")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ma_nhan_vien")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "EmployeeID", "")}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Phong_ban_moi")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "NewDepartmentName", "")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_To_nhom_moi")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "NewTeamName", "")}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Du_an_moi")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "NewProjectName", "")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Cong_viec_moi")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "NewWorkName", "")}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ngay_hieu_luc")}
                                fullWidth={true}
                                value={Config.convertDate(data.ValidDate, "")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ly_do")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "ProContent", "")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ghi_chu")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "ProNotice","")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        {
                            data.IsSalaryAdjustment === 1 && dataColumnSalary.map((value,index) => {
                                 if(value.Disabled) return null;
                                 let key = value.Code.slice(-2);
                                 const item = data["NewBASE" + key] || data["NewBASE" + key] === 0 ? Number(data["NewBASE" + key]) : 0;
                                 return (
                                    <Col key={index} xs={12} sm={12} md={12} lg={12}>
                                        <TextField
                                            label={Config.lang(value.CaptionName) + " " + Config.lang("DHR_Moi").toLowerCase()}
                                            variant={"standard"}
                                            margin={"normal"}
                                            value={String(item)}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                min: 0,
                                                inputComponent: NumberFormatCustom
                                            }}
                                            fullWidth
                                        />
                                    </Col>
                                 )
                            })
                        }
                        {data.IsSalaryAdjustment === 1 && getCaption && getCaption.map((item, index) => {
                            if (item.Disabled) return null;
                            let no          = item && item.Code ? item.Code.slice(-2) : "";
                            const dataField = "NewSalCoefficient" + no;
                            return (<Col key={index} xs={12} sm={12} md={6} lg={6}>
                                <MoneyInput
                                    style={{paddingTop: 10}}
                                    allowZero={true}
                                    decimalDigit={item.Decimals ? item.Decimals : 0}
                                    disabledNegative
                                    label={item.CaptionName + ' ' + Config.lang('DHR_Moi').toLowerCase()}
                                    placeholder={Config.lang("Nhap")}
                                    readOnly
                                    value={data[dataField] ? String(data[dataField]) : "0"}
                                />
                            </Col>);
                        })}

                    </Row>
                </FormGroup>
                <FormGroup className={"mgt10"}>
                    <ExpansionPanel className={classes.expandPanel + " mgb10"}
                                    onChange={(e, isExpaned) => {
                                        this.setState({isExpanded: isExpaned})
                                    }}>
                        <ExpansionPanelSummary
                            href={""}
                            className={classes.expandSummary}
                            // expandIcon={<img alt={""} src={require('../../../../assets/images/icon-expand.svg')} />}
                            aria-label="Expand"
                        >
                            <h5 style={{margin: '5px 0'}}><b>{Config.lang("DHR_Thong_tin_cu")}</b></h5>&nbsp;
                            {isExpanded ?
                                <Image alt={""}
                                       src={require('../../../../assets/images/icon-collapse.svg')}/>
                                :
                                <Image alt={""}
                                       src={require('../../../../assets/images/icon-expand.svg')}/>
                            }
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.expandDetail}>
                            <FormGroup style={{padding: 10}}>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <span style={{fontWeight: 600}}>{Config.lang("DHR_Phong_ban")}:</span>&nbsp;
                                        {Config.helpers.getObjectValue(data, "DepartmentName", "")}
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <span style={{fontWeight: 600}}>{Config.lang("DHR_To_nhom")}:</span>&nbsp;
                                        {Config.helpers.getObjectValue(data, "TeamName", "")}
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <span style={{fontWeight: 600}}>{Config.lang("DHR_Du_an_cu")}:</span>&nbsp;
                                        {Config.helpers.getObjectValue(data, "ProjectName", "")}
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <span style={{fontWeight: 600}}>{Config.lang("DHR_Cong_viec_cu")}:</span>&nbsp;
                                        {Config.helpers.getObjectValue(data, "WorkName", "")}
                                    </Col>
                                    {
                                        data.IsSalaryAdjustment === 1 && dataColumnSalary.map((value,index) => {
                                            if(value.Disabled) return null;
                                            let key = value.Code.slice(-2);
                                            const item = data["BASE" + key] || data["BASE" + key] === 0 ? Number(data["BASE" + key]) : 0;
                                            return (
                                                <Col key={index} xs={12} sm={12} md={6} lg={6}>
                                                    <span style={{fontWeight: 600}}>{Config.lang(value.CaptionName)}:</span>&nbsp;
                                                    {new Intl.NumberFormat().format(item)}
                                                </Col>
                                            )
                                        })
                                    }
                                    {
                                        Number(data.IsSalaryAdjustment) === 1 && getCaption && getCaption.map((field, indx) => {
                                        if (field.Disabled) return null;
                                        const no = field && field.Code ? field.Code.slice(-2) : "";
                                        const dataField = "SalCoefficient" + no;
                                        return (
                                            <Col key={indx} xs={12} sm={6} md={6} lg={6}>
                                                <span style={{fontWeight: 600}}>{field.CaptionName || ""}:</span>&nbsp;
                                                {Number(data[dataField] || 0).toLocaleString()}
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </FormGroup>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </FormGroup>
            </>
        )
    };

    render() {
        const {iPermission, dataForm, VoucherID, loading} = this.state;
        if (!iPermission) return null;

        return (
            <React.Fragment>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("DHR_Duyet_de_xuat_dieu_chuyen_lao_dong")}
                    />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Approvals
                                parentProps={this.props}
                                loading={loading}
                                FormID={"W09F2081"}
                                dataForm={dataForm}
                                ref={'Approvals'}
                                selectedRowKey={VoucherID}
                                disabledApproval={iPermission < 2}
                                singleClick
                                formInfoRender={this.renderFormInfo}
                                onSelectionChanged={this.onSelectionChanged}
                                onDrawerClosed={this.onDrawerClosed}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        );
    }

}

class NumberFormatCustom extends React.Component {
    render() {
        const {name, inputRef, onChange, ...props} = this.props;
        return (
            <NumberFormat
                {...props}
                // value={value}
                getInputRef={inputRef}
                thousandSeparator={true}
                onValueChange={(e) => {
                    if (onChange) {
                        onChange({
                            target: {
                                name: name,
                                value: e.value
                            }
                        })
                    }
                }}
            />
        );
    }
}

export default compose(connect(state=>({
        getCaption: state.W09F2150.getCaption,
    }),
    (dispatch) => ({
        w09F2081Actions: bindActionCreators(W09F2081Actions, dispatch),
        w09F2150Actions: bindActionCreators(W09F2150Actions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
        approvalActions: bindActionCreators(ApprovalActions, dispatch),
    })
), withStyles(styles, {withTheme: true}))(W09F2081);
