/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 3/13/2020
 * @Example
 */
import React, { Component } from "react";

import { connect } from "react-redux";
import Config from "../../../../config";
import * as W09F2080Actions from "../../../../redux/W0X/W09F2080/W09F2080_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import { bindActionCreators, compose } from "redux";

import _ from "lodash";
import moment from "moment";
import { browserHistory } from "react-router";
import { Column } from "devextreme-react/data-grid";
import { withStyles, IconButton, Divider } from "@material-ui/core";
import { Image, FormGroup, Row, Col } from "react-bootstrap";

import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ButtonGeneral from "../../../common/button/button-general";
import GridContainer from "../../../grid-container/grid-container";
// import Modal from "../../../common/modal/modal";
import Filter from "../../../filter/filter";
import DateBoxPicker from "../../../common/form-material/date-box";
import { Combo } from "../../../common/form-material";
import W09F2080Popup from "../W09F2080/W09F2080Popup";
import Modal from "../../../common/modal/modal";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import Status from "../../../common/status/status";
import Icons from "../../../common/icons/";

const styles = {
  divAvatar: {
    width: 46,
    height: 46,
    minWidth: 46,
    minHeight: 46,
    borderRadius: "50%",
    overflow: "hidden",
    marginRight: 8
  },
  imgAvatar: {
    maxWidth: "100%",
    objectFit: "contain"
  },
  dateInfo: {
    display: "flex",
    alignItems: "center"
  },
  divText: {
    // fontSize: "14px",
    fontWeight: 500,
    // color: "#575757"
  },
  divDateText: {
    // fontSize: "14px",
    fontWeight: 500,
    // color: "#000000"
  }
};

class W09F2080 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iPermission: 0,
      mode: "add",
      rowData: null,
      gridLoading: false,
      showW09F2080Popup: false,
      ProDateFrom: null,
      ProDateTo: null,
      dataCboEmployees: {
        rows: [],
        total: 0
      },
      dataCboProjects: {
        rows: [],
        total: 0
      },
      loading: {
        cboEmployees: false,
        cboProjects: false,
      },
    };
    this.loading = {
      cboEmployees: false,
      cboProjects: false,
    };
    this.filter = {
      Employee: "%",
      DepartmentID: "",
      Project: "%",
      AppStatusID: "%",
      skip: 0,
      limit: 10
    };
    this.filterCboEmployees = {
      timer: null,
      strSearch: "",
      skip: 0,
      limit: 50
    };
    this.filterCboProject = {
      timer: null,
      strSearch: "",
      skip: 0,
      limit: 50
    };
  }

  loadPermission = async () => {
    await this.props.generalActions.getPermission("W09F2080", isPer => {
      this.setState({ iPermission: isPer });
    });
  };

  loadData = (VoucherID) => {
    const {voucher_id} = Config.getUrlParams(this.props);
    VoucherID = VoucherID ? VoucherID : voucher_id;
    const { ProDateFrom, ProDateTo } = this.state;
    const {AppStatusID, Employee, DepartmentID, Project, limit, skip} = this.filter;
    const param = {
      TransID: VoucherID || "",
      FormID: "W09F2080",
      Language: Config.language || 84,
      ProDateFrom,
      ProDateTo,
      AppStatusID: AppStatusID,
      EmployeeID: Employee && Employee.EmployeeID ? Employee.EmployeeID : "%",
      DepartmentID: DepartmentID,
      ProjectID: Project && Project.ProjectID ? Project.ProjectID : "%",
      limit: limit,
      skip: skip
    };
    this.setState({ gridLoading: true });
    this.props.w09f2080Actions.loadData(param, error => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
      this.setState({ gridLoading: false });
    });
  };

  loadCboEmployees = (isReset) => {
    const param = {
      HostID: "",
      FormID: "W09F2080",
      Language: Config.language || "84",
      skip: this.filterCboEmployees.skip,
      limit: this.filterCboEmployees.limit,
      search: this.filterCboEmployees.strSearch
    };
    this.loading.cboEmployees = true;
    this.setState({loading: this.loading});
    this.props.w09f2080Actions.loadCboEmployees(param, (error, data) => {
      this.loading.cboEmployees = false;
      this.setState({loading: this.loading});
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
      if (data) {
        const {dataCboEmployees} = this.state;
        const rows = data && data.rows ? data.rows : data;
        const total = data && data.total ? data.total : data.length;
        this.setState({
          dataCboEmployees: {
            rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
            total: total
          }
        })
      }
    });
  };

  loadCboProjects = (isReset) => {
    const param = {
      HostID: "",
      FormID: "W09F2080",
      Language: Config.language || "84",
      skip: this.filterCboProject.skip,
      limit: this.filterCboProject.limit,
      search: this.filterCboProject.strSearch
    };
    this.loading.cboProjects = true;
    this.setState({loading: this.loading});
    this.props.w09f2080Actions.loadCboProjects(param, (error, data) => {
      this.loading.cboProjects = false;
      this.setState({loading: this.loading});
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
      if (data) {
        const {dataCboProjects} = this.state;
        const rows = data && data.rows ? data.rows : data;
        const total = data && data.total ? data.total : data.length;
        this.setState({
          dataCboProjects: {
            rows: isReset ? rows : dataCboProjects.rows.concat(rows),
            total: total
          }
        })
      }
    });
  };

  loadCboDepartment = () => {
    const param = {
      HostID: "",
      FormID: "W09F2080",
      DivisionID: Config.getDivisionID(),
      Language: Config.language || "84"
    };

    this.props.w09f2080Actions.loadCboDeparments(param, error => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
    });
  };
  loadCboAppStatus = () => {
    const param = {
      Language: Config.language || "84"
    };

    this.props.w09f2080Actions.loadCboAppStatus(param, error => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
    });
  };

  onChangePage = page => {
    this.filter.skip = page * this.filter.limit;
    this.loadData();
  };

  onChangePerPage = perPage => {
    this.filter.skip = 0;
    this.filter.limit = perPage;
    this.loadData();
  };

  onChangePageProfile = data => {
    browserHistory.push({
      pathname: Config.getRootPath() + "W09F2000",
      state: { EmployeeID: data.EmployeeID }
    });
  };

  onSearch = () => {
    this.loadData();
  };

  onCloseModal = (isSaved = false) => {
    if(isSaved) this.loadData();
    this.setState({
      showW09F2080Popup: false
    });
  };

  onAdd = () => {
    this.setState({
      mode: "add",
      showW09F2080Popup: true
    });
  };

  onView = e => {
    const { data } = e.row;
    this.setState({
      mode: "view",
      rowData: data,
      showW09F2080Popup: true
    });
  };

  onDelete = data => {
    const ProTransID = _.get(data, "ProTransID", "");
    const param = {
      ProTransID,
      FormID: ""
    };
    Config.popup.show("YES_NO", Config.lang("DHR_Ban_co_chac_muon_xoa?"), () => {
      this.props.w09f2080Actions.deleteDataGrid(param, error => {
        if (error) {
          Config.popup.show("INFO", Config.lang("DHR_Loi_chua_xac_dinh"));
          return false;
        } else {
          Config.notify.show("success", Config.lang("DHR_Xoa_thanh_cong"), 3000);
          this.loadData();
        }
      });
    });
  };

  filterChange = (key, data) => {
    const value = _.get(data, "value", null);
    if (!key) return false;
    switch (key) {
      case "DateFrom":
        this.setState({
          ProDateFrom: value
        });
        break;
      case "DateTo":
        this.setState({
          ProDateTo: value
        });
        break;
      case "AppStatusID":
        this.filter.AppStatusID = !Config.isEmpty(value, true) ? value : "%";
        break;
      case "EmployeeID":
        this.filter.Employee = value ? value : "%";
        break;

      case "DepartmentID":
        this.filter.DepartmentID = value ? value : "%";
        break;
      case "ProjectID":
        this.filter.Project = value ? value : "%";
        break;
      default:
        break;
    }
  };

  renderEmpProfile = e => {
    const { classes } = this.props;
    const data = _.get(e, "data", "");
    const date = moment.utc(data.CreateDate).format("LT, DD/MM/YYYY");
    if (data) {
      if (data.UserPictureURL) {
        data.UserPictureURL = data.UserPictureURL.indexOf('http') < 0 ? Config.getCDNPath() + data.UserPictureURL : data.UserPictureURL;
      } else if (data.EmployeeID) {
        const user = Config.getUser({EmployeeID: data.EmployeeID});
        data.UserPictureURL = user && user.UserPictureURL ? user.UserPictureURL : ""
      }
    }
    return (
      <div className={"display_row align-center pdt10 pdb10"}>
        <div className={classes.divAvatar}>
          <UserImage data={data} onClick={() => this.onChangePageProfile(data)} />
        </div>
        <div className={"w75f2000_info"}>
          <div className={classes.divText}><UserName data={data}/></div>
          <div className={classes.divText} style={{whiteSpace:"break-spaces"}}>{data.DepartmentName || ""}</div>
          <div className={classes.dateInfo}>
            <Image src={require("../../../../assets/images/icon-calendar.svg")} />
            <div style={{ paddingLeft: "8px" }} className={"date-text-info"}>
              {date}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderInfo = e => {
    const { classes } = this.props;
    const data = _.get(e, "data", "");
    const date = moment.utc(data.ProposalDate).format("LT, DD/MM/YYYY");
    return (
      <div className={"pdt10 pdb10 " + classes.divText}>
        {data.NewDepartmentName && (
          <div className={classes.divDateText}>
            {Config.lang("DHR_Phong_ban_moi")}: {data.NewDepartmentName}
          </div>
        )}
        {data.NewProjectName && (
          <div className={classes.divDateText}>
            {Config.lang("DHR_Du_an_moi")}: {data.NewProjectName}
          </div>
        )}
        {data.ProposerName && (
          <div className={classes.divDateText}>
            {Config.lang("DHR_Nguoi_lap")}: {data.ProposerName}
          </div>
        )}
        {data.ApproverName && (
          <div className={classes.divDateText}>
            {Config.lang("DHR_Nguoi_duyet")}:{data.ApproverName || ""} - {data.DepartmentID || ""} -
            {data.ApprovalDate || ""}
          </div>
        )}
        <div>
          {Config.lang("DHR_Ngay_hieu_luc")}: {date || ""}
        </div>
        {data.AppStatusID && (
          <div className={classes.divDateText}>
            {Config.lang("DHR_Ghi_chu_duyet")}:{data.ApprovalNotes || ""}
          </div>
        )}
      </div>
    );
  };

  renderStatus = e => {
    const data = _.get(e, "data", "");
    return <Status data={data} />;
  };

  renderAction = e => {
    const { iPermission } = this.state;
    const data = _.get(e, "data", 0);
    const allowDelete = iPermission > 3 && Number(data.AppStatusID) === 0;
    return (
      <div className={"display_row align-center"}>
        <IconButton aria-label="view" size="small" onClick={() => this.onView(e)}>
          <Icons name={"view"}/>
        </IconButton>
        <Divider orientation="vertical" style={{ width: 2, height: 11, margin: "0 5px" }} />
        <IconButton disabled={!allowDelete} aria-label="delete" size="small" onClick={() => this.onDelete(data)}>
          <Icons name={"delete"}/>
        </IconButton>
      </div>
    );
  };

  renderFilter = () => {
    const { getCboAppStatus, getCboDepartments, classes } = this.props;
    const { Project, AppStatusID, Employee, DepartmentID } = this.filter;
    const { ProDateTo, ProDateFrom /*showW09F2080Popup, mode, rowData*/, dataCboEmployees, dataCboProjects, loading } = this.state;
    return (
      <Filter
        placeholder={Config.lang("DHR_Nhap_tu_tim_kiem")}
        openOnFieldClick={true}
        renderFilter={() => {
          return (
            <>
              {/* <Modal
                open={showW09F2080Popup}
                maxWidth={"md"}
                fullWidth={true}
                onClose={this.onCloseModal}
                title={Config.lang("DHR_Lap_de_xuat_ky_luat")}
              >
                <W09F2080Popup mode={mode} data={rowData} onClose={this.onCloseModal} />
              </Modal> */}
              <FormGroup style={{ marginBottom: "7px" }}>
                <Row>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className={classes.divText}>{Config.lang("DHR_Tu")}</div>
                      <DateBoxPicker
                        dateBoxProps={{ max: ProDateTo }}
                        style={{ marginLeft: "5px" }}
                        onValueChanged={e => this.filterChange("DateFrom", e)}
                        useMaskBehavior={true}
                        showClearButton={true}
                        placeholder={"DD/MM/YYYY"}
                        value={ProDateFrom}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className={classes.divText}>{Config.lang("DHR_Den").toLowerCase()}</div>
                      <DateBoxPicker
                        dateBoxProps={{ min: ProDateFrom }}
                        style={{ marginLeft: "5px" }}
                        onValueChanged={e => this.filterChange("DateTo", e)}
                        useMaskBehavior={true}
                        showClearButton={true}
                        placeholder={"DD/MM/YYYY"}
                        value={ProDateTo}
                      />
                    </div>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup style={{ marginBottom: "7px" }}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Combo
                      label={Config.lang("DHR_Trang_thai_duyet")}
                      dataSource={getCboAppStatus}
                      displayExpr={"AppStatusName"}
                      valueExpr={"AppStatusID"}
                      onValueChanged={e => this.filterChange("AppStatusID", e)}
                      showClearButton={true}
                      value={AppStatusID === "%" ? "" : AppStatusID}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup style={{ marginBottom: "7px" }}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Combo.LoadMore
                        dataSource={dataCboEmployees}
                        skip={this.filterCboEmployees.skip}
                        limit={this.filterCboEmployees.limit}
                        displayExpr={"EmployeeName"}
                        keyExpr={'EmployeeID'}
                        valueExpr={'EmployeeID'}
                        value={Employee === "%" ? null : Employee}
                        stylingMode={'outlined'}
                        loading={loading.cboEmployees}
                        label={Config.lang('DHR_Nhan_vien')}
                        itemRender={(e) => {
                          const {data} = e;
                          if (!data) return null;
                          return data.EmployeeID + ' - ' + data.EmployeeName;
                        }}
                        showClearButton={true}
                        // showCheckBoxMode={"none"}
                        onValueChanged={e => this.filterChange("EmployeeID", e)}
                        onInputChanged={(e) => {
                          this.filterCboEmployees.strSearch = e.target.value;
                          this.filterCboEmployees.skip = 0;
                          this.loadCboEmployees(true);
                        }}
                        onLoadMore={(e) => {
                          this.filterCboEmployees.skip = e.skip;
                          this.filterCboEmployees.limit = e.limit;
                          this.loadCboEmployees();
                        }}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup style={{ marginBottom: "7px" }}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Combo
                      label={Config.lang("DHR_Phong_ban_moi")}
                      dataSource={getCboDepartments}
                      valueExpr={"DepartmentID"}
                      displayExpr={"DepartmentName"}
                      onValueChanged={e => this.filterChange("DepartmentID", e)}
                      showClearButton={true}
                      value={DepartmentID === "%" ? "" : DepartmentID}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup style={{ marginBottom: "7px" }}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Combo.LoadMore
                        dataSource={dataCboProjects}
                        skip={this.filterCboProject.skip}
                        limit={this.filterCboProject.limit}
                        displayExpr={'ProjectName'}
                        keyExpr={'ProjectID'}
                        valueExpr={'ProjectID'}
                        value={Project === "%" ? null : Project}
                        loading={loading.cboProjects}
                        stylingMode={'outlined'}
                        label={Config.lang('DHR_Du_an_moi')}
                        itemRender={(e) => {
                          const {data} = e;
                          if (!data) return null;
                          return data.ProjectID + ' - ' + data.ProjectName;
                        }}
                        showClearButton={true}
                        // showCheckBoxMode={"none"}
                        onValueChanged={e => this.filterChange("ProjectID", e)}
                        onInputChanged={(e) => {
                          this.filterCboProject.strSearch = e.target.value;
                          this.filterCboProject.skip = 0;
                          this.loadCboProjects(true);
                        }}
                        onLoadMore={(e) => {
                          this.filterCboProject.skip = e.skip;
                          this.filterCboProject.limit = e.limit;
                          this.loadCboProjects();
                        }}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "38px",
                  paddingBottom: "40px"
                }}
              >
                <ButtonGeneral
                    name={Config.lang("DHR_Tim_kiem")}
                    typeButton={"search"}
                    size={"large"}
                    color={"primary"}
                    variant={"outlined"}
                    onClick={this.onSearch}
                />
              </div>
            </>
          );
        }}
      />
    );
  };

  componentDidMount = async () => {
    await this.loadPermission();
    if (!this.state.iPermission) return;
    this.loadData();
    this.loadCboDepartment();
    this.loadCboEmployees();
    this.loadCboProjects();
    this.loadCboAppStatus();
  };

  componentDidUpdate (prevProps, prevState, snapshot) {
    const {getData} = this.props;
    Config.callChildForm({
      FormID: "W09F2080",
      // ID: voucher_id,
      keyExpr: "ProTransID",
      data: getData && getData.rows ? getData.rows : [],
      onLoad: (params) => this.loadData(params && params.ID),
      onAction: (e, data) => this.onView({ row: { data: data } })
    }, this.props);
  }

  // UNSAFE_componentWillReceiveProps = (nextProps) => {
  //   const {ProTransID} = this.getInfo(nextProps);
  //   if(ProTransID && this.ProTransID !== ProTransID){
  //     this.ProTransID=ProTransID;
  //     this.checkNotify(ProTransID);
  //   }
  // };
  //
  // checkNotify = (ProTransID) => {
  //   if(ProTransID) {
  //     this.onView({row:{data:{ProTransID:ProTransID}}});
  //     browserHistory.push(Config.getRootPath() + 'W09F2080');
  //     this.EmployeeID=null;
  //   }
  // };
  //
  // getInfo = (props) => {
  //   if(!props) return {};
  //   const {location} = props ? props : {};
  //   const url = new window.URLSearchParams(window.location.search);
  //   if (url && url.get('voucher_id')) {
  //     return {ProTransID: url.get('voucher_id')};
  //   } else if (location && location.state) {
  //     return {
  //       ProTransID: location.state.voucher_id,
  //     }
  //   } else {
  //     return {};
  //   }
  // };

  render() {
    const { gridLoading, iPermission, showW09F2080Popup, rowData, mode } = this.state;
    const { getData } = this.props;
    const { skip, limit } = this.filter;
    if (iPermission <= 0) return null;
    const dataRows = _.get(getData, "rows", "");
    const totalRow = Number(_.get(getData, "total", 0));
    return (
      <>
        <Modal
          open={showW09F2080Popup}
          maxWidth={"md"}
          fullWidth={true}
          onClose={() => this.onCloseModal(false)}
          title={Config.lang("DHR_Lap_de_xuat_dieu_chuyen_lao_dong")}
        >
          <W09F2080Popup mode={mode} data={rowData} onClose={this.onCloseModal} />
        </Modal>
        <div className={"hidden"}>{this.renderFilter()}</div>
        <ActionToolbar title={Config.lang("DHR_De_xuat_dieu_chinh_lao_dong")}>
          <ButtonGeneral
            disabled={!iPermission > 1}
            name={Config.lang("DHR_Them")}
            typeButton={"add"}
            style={{ textTransform: "uppercase" }}
            size={"large"}
            onClick={this.onAdd}
          />
        </ActionToolbar>
        <GridContainer
          style={{ border: "none" }}
          dataSource={dataRows}
          showRowLines={true}
          showBorders={false}
          showColumnLines={false}
          showColumnHeaders={false}
          noDataText={Config.lang("DHR_No_data")}
          hoverStateEnabled={true}
          //   onCellClick={this.onDetail}
          loading={gridLoading}
          totalItems={totalRow}
          itemPerPage={limit}
          skipPerPage={skip}
          typePaging={"remote"}
          height={Config.getHeightGrid()}
          onChangePage={this.onChangePage}
          onChangePerPage={this.onChangePerPage}
        >
          <Column width={250} cellRender={this.renderEmpProfile} />
          <Column cellRender={this.renderInfo} />
          <Column cellRender={this.renderStatus} alignment={"center"} width={200} />
          <Column cellRender={this.renderAction} width={200} />
        </GridContainer>
      </>
    );
  }
}

export default compose(
  connect(
    state => ({
      getCboEmployees: state.W09F2080.getCboEmployees,
      getCboProjects: state.W09F2080.getCboProjects,
      getCboDepartments: state.W09F2080.getCboDepartments,
      getCboAppStatus: state.W09F2080.getCboAppStatus,
      getData: state.W09F2080.getData
    }),
    dispatch => ({
      generalActions: bindActionCreators(generalActions, dispatch),
      w09f2080Actions: bindActionCreators(W09F2080Actions, dispatch)
    })
  ),
  withStyles(styles)
)(W09F2080);
