/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 9/25/2020
 * @Example
 */

import React                         from "react";
import {connect}                     from "react-redux";
import {bindActionCreators, compose} from "redux";
import PropTypes                     from "prop-types";
import {Row, Col, FormGroup, Image}  from "react-bootstrap";
import {
    withStyles,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    IconButton
}                                    from "@material-ui/core";
import AttachFileIcon                from "@material-ui/icons/AttachFile";
import NumberFormat                  from "react-number-format";
import {
    Combo,
    MForm,
    TextField,
    Checkbox
}                           from "../../../common/form-material";
import Attachments          from "../../../common/attachments/attachments";
import Modal                from "../../../common/modal/modal";
import ButtonGeneral        from "../../../common/button/button-general";
import * as generalActions  from "../../../../redux/general/general_actions";
import * as W09F2150Actions from "../../../../redux/W0X/W09F2150/W09F2150_actions";
import Config               from "../../../../config";
import DateBoxPicker        from "../../../common/form-material/date-box";

const styles = {
    expandPanel: {
        boxShadow: 'none'
    },
    expandSummary: {
        minHeight: '37px !important',
        padding: 0,
        '& .MuiExpansionPanelSummary-content': {
            margin: 0
        }
    },
    expandDetail: {
        display: 'block',
        padding: '0 0 10px 0'
    }
};

class W09F2150Popup extends React.PureComponent {
    constructor (props) {
        super(props);
        this.state = {
            //dataForm
            Employee:           null,
            ValidDate:          null,
            ProDutyID:             "",
            ProContent:         "",
            ProNotice:          "",
            IsSalaryAdjustment: 0,

            isExpanded:         false,
            disabled:           false,
            error:              {},
            loading:            false,
            uploading:          false,
            dataOldAttachments: [],

            dataForm:    {},
            dataOldForm: {},
        };
        this.attachments       = [];
        this.deletedFile       = [];
    }

    loadForm = () => {
        const {mode, data} = this.props;
        const params = {
            ProTransID: data && data.ProTransID ? data.ProTransID : "",
            Language:   Config.language || "84"
        };
        this.setState({loading: true});
        this.props.W09F2150Actions.loadForm(params, (error, data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const user = Config.getUser({EmployeeID: data.EmployeeID});
                this.setState({
                    ...this.state,
                    ...data,
                    ...(mode !== "add" ? {Employee: user ? user : data} : {}),
                    dataOldForm: data
                });
            }
        });
    };

    loadDynamicFields = () => {
        this.setState({loading: true});
        this.props.W09F2150Actions.loadDynamicFields((error) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCaption = () => {
        this.setState({loading: true});
        this.props.W09F2150Actions.loadCaption({Language: Config.language || "84" },(error, data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadAttachments = () => {
        const {data} = this.props;
        const param  = {
            KeyID: data && data.ProTransID ? data.ProTransID : ""
        };
        this.setState({loading: true});
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                this.setState({loading: false});
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data,
                }, () => {
                    this.setState({loading: false});
                });
            }
        });
    };

    loadCboDuty = () => {
        this.props.generalActions.getCboDuty2(error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    componentDidMount () {
        const {mode} = this.props;
        this.loadDynamicFields();
        this.loadCaption();
        if (mode !== "add") {
            this.loadForm();
            this.loadAttachments();
            this.setState({disabled: true});
        }
        if(mode === "view") {
            this.loadCboDuty();
        }
    }

    handleChange = (key, e) => {
        if (!key) return false;
        const {getDynamicFields, getCaption} = this.props;
        switch (key) {
            case "ProContent": {
                this.setState({ProContent: e.target.value});
                break;
            }
            case "ProNotice": {
                this.setState({ProNotice: e.target.value});
                break;
            }
            case "EmployeeID": {
                this.setState({
                    Employee: e.value,
                    dataOldForm: e.value,

                }, () => {
                    let obj = {};
                    getDynamicFields.forEach(d => {
                        let no = d && d.Code ? d.Code.slice(-2) : "";
                        obj['ProBaseSalary' + no] = "";
                    });
                    getCaption.forEach(d => {
                        let no = d && d.Code ? d.Code.slice(-2) : "";
                        obj['NewSalCoefficient' + no] = "";
                    });
                    this.setState({
                        ...this.state.dataForm,
                        ...obj
                    });
                });
                break;
            }
            case "ProDutyID": {
                this.setState({ProDutyID: e.value});
                break;
            }
            case "ValidDate": {
                this.setState({ValidDate: e.value});
                break;
            }
            default:
                if (key.indexOf("BaseSalary") > -1 || key.indexOf("SalCoefficient") > -1) {
                    this.setState({[key]: e.value});
                }
                break;
        }
    };

    setStateErrorText (objValue, key) {
        const {errorHelperText} = this.state;
        const stateError        = key ? key : "error";
        this.setState({
            [stateError]: {
                ...errorHelperText, ...objValue
            }
        });
        return Object.keys(objValue).length !== 0;
    }

    onSave = () => {
        const {mode, data, getDynamicFields, getCaption} = this.props;
        const isEdit             = mode === "view" || false;
        const state = this.state;
        const {
                  ProContent, ProNotice, Employee, ValidDate, IsSalaryAdjustment,dataOldForm
              } = state;

        const arrAttachments = this._getAttachments();
        let params           = {
            FormID:             "W09F2151",
            EmployeeID:         Employee && Employee.EmployeeID ? Employee.EmployeeID : "",
            TempDutyID:         dataOldForm.TempDutyID ? dataOldForm.TempDutyID : (state.TempDutyID ? state.TempDutyID : ""),
            ProDutyID:          state.ProDutyID ? state.ProDutyID : "",
            IsSalaryAdjustment: IsSalaryAdjustment || 0,
            ProContent:         ProContent,
            ProNotice:          ProNotice,
            ValidDate:          ValidDate,
            Language:           Config.language || "84",
            DivisionID:         Config.getDivisionID(),
            arrAttachment:      JSON.stringify(arrAttachments)
        };

        getDynamicFields.forEach(field => {
            if (!field.Disabled) {
                let no = field && field.Code ? field.Code.slice(-2) : "";
                params["TempBaseSalary" + no] = state["TempBaseSalary" + no] ? state["TempBaseSalary" + no] : dataOldForm["TempBaseSalary" + no];
                params["ProBaseSalary" + no]  = state["ProBaseSalary" + no] ? state["ProBaseSalary" + no] : 0;
            }
        });

        getCaption.forEach(field => {
            if (!field.Disabled) {
                let no = field && field.Code ? field.Code.slice(-2) : "";
                params["SalCoefficient" + no] = state["SalCoefficient" + no] ? state["SalCoefficient" + no] : dataOldForm["SalCoefficient" + no];
                params["NewSalCoefficient" + no]  = state["NewSalCoefficient" + no] ? Number(state["NewSalCoefficient" + no] || 0) : 0;
            }
        });

        if (isEdit) params.ProTransID = data && data.ProTransID ? data.ProTransID : "";
        const validateRules = [
            {
                name: "EmployeeID",
                rules: ["isRequired"],
                value: params.EmployeeID ? params.EmployeeID : ""
            },
            {
                name: "ValidDate",
                rules: ["isRequired"],
                value: params.ValidDate ? params.ValidDate : null
            },
            {
                name: "ProDutyID",
                rules: ["isRequired"],
                value: params.ProDutyID ? params.ProDutyID : ""
            },

        ];
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            this.setStateErrorText(validateForm);
            return false;
        } else {
            this.setState({loading: true});
            this.props.W09F2150Actions[isEdit ? "edit" : "save"](params, (error,data) => {
                this.setState({loading: false});
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                if(data) {
                    if(data.Status === 1) {
                        let message = data.Message || Config.lang("DHR_Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        return false;
                    }
                }
                Config.notify.show('success', Config.lang("DHR_Luu_thanh_cong"), 2000);
                this.onClose();
            });
        }
    };

    resetForm = () => {
        this.setState({
            Employee:           null,
            ValidDate:          null,
            DutyID:             "",
            ProContent:         "",
            ProNotice:          "",

            IsSalaryAdjustment: 0,
            disabled:           false,
            loading:            false,
            uploading:          false,
            dataOldAttachments: [],
            dataForm:           {},
            dataOldForm:        {},

            error:              {}
        });
    };

    onClose = () => {
        const {onClose} = this.props;
        this.resetForm();
        if (onClose) onClose();
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    onUploading = value => {
        this.setState({uploading: value});
    };

    _getAttachments = () => {
        const listAttachments      = Config.helpers.getFileInfomations(this.attachments);
        const {dataOldAttachments} = this.state;

        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL:      att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt:  att.fileExt ? att.fileExt : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            }));
        }
    };

    onChangeChkSalary = (chk) => {
        const { getDynamicFields, getCaption } = this.props;
        const { Employee } = this.state;

        this.setState({
            IsSalaryAdjustment: chk && chk.target.checked ? 1 :0
        }, () => {
            let obj = {};
            getDynamicFields.forEach(d => {
                let no = d && d.Code ? d.Code.slice(-2) : "";
                obj['ProBaseSalary' + no] = Employee?.['TempBaseSalary' + no];
            });
            getCaption.forEach(d => {
                let no = d && d.Code ? d.Code.slice(-2) : "";
                obj['NewSalCoefficient' + no] = Employee?.['SalCoefficient' + no];
            });
            this.setState({
                ...this.state.dataForm,
                ...obj
            });
        })

    };

    render () {
        let {classes, getCboDuty, getDynamicFields, data, mode, getCaption, getCboEmployees} = this.props;
        const state = this.state;
        const {uploading, error, loading, dataOldAttachments, disabled,
                  disabledEmp, isExpanded, dataOldForm
              } = state;
        const employee = getCboEmployees?.find?.(i=>i.EmployeeID === state.Employee);
        return (
            <>
                <Modal.Content>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Combo.Employees
                                acceptCustomValue={true}
                                error={error && error["EmployeeID"]}
                                displayExpr={'EmployeeName'}
                                keyExpr={'EmployeeID'}
                                valueExpr={'EmployeeID'}
                                subTextExpr={"EmployeeID"}
                                value={state && state.Employee ? state.Employee : null}
                                FormID={"W09F2150"}
                                stylingMode={'underlined'}
                                shrink={true}
                                required={true}
                                disabled={loading || disabledEmp ||  Number(data?.AppStatusID) === 4 }
                                label={Config.lang('DHR_Nhan_vien')}
                                dropdownProps={{
                                    readOnly: disabled
                                }}
                                onValueChanged={e => this.handleChange("EmployeeID", e)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <DateBoxPicker
                                height={28}
                                useMaskBehavior={true}
                                label={Config.lang("DHR_Ngay_hieu_luc")}
                                width={"100%"}
                                stylingMode={"underlined"}
                                shrink={true}
                                InputLabelProps={{style: {marginTop: "-7px"}}}
                                value={state && state.ValidDate}
                                onValueChanged={e => this.handleChange("ValidDate", e)}
                                margin={"normal"}
                                disabled={loading || Number(data?.AppStatusID) === 4}
                                error={error && error["ValidDate"]}
                                required={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Combo
                                error={error && error["ProDutyID"]}
                                disabled={loading || disabled || Number(data?.AppStatusID) === 4}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                margin={"normal"}
                                stylingMode={"underlined"}
                                label={Config.lang("DHR_Chuc_vu_moi")}
                                dataSource={Config.storeDataSoureDevExtreme(getCboDuty)}
                                displayExpr={"DutyName"}
                                valueExpr={"DutyID"}
                                required={true}
                                onValueChanged={e => this.handleChange("ProDutyID", e)}
                                showClearButton={true}
                                value={state && state.ProDutyID}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                error={error && error["ProContent"]}
                                label={Config.lang("DHR_Ly_do")}
                                variant={"standard"}
                                margin={"normal"}
                                value={state && state.ProContent}
                                disabled={loading}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    readOnly: Boolean(Number(data?.AppStatusID) === 4),
                                }}
                                onChange={(e) => this.handleChange("ProContent", e)}
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                error={error && error["ProNotice"]}
                                label={Config.lang("DHR_Ghi_chu")}
                                variant={"standard"}
                                margin={"normal"}
                                value={state && state.ProNotice}
                                disabled={loading || Number(data?.AppStatusID) === 4}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                helperText={this.state.helperTextQuantity}
                                onChange={e => this.handleChange("ProNotice", e)}
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <FormGroup>
                        <ExpansionPanel expanded={Boolean(state.IsSalaryAdjustment)}
                                        className={classes.expandPanel}>
                            <ExpansionPanelSummary href={''}
                                className={classes.expandSummary}
                                style={{padding: 0}}
                            >
                                <Checkbox label={Config.lang("DHR_Dieu_chinh_luong")}
                                          style={{marginLeft: -10}}
                                          checked={Boolean(state.IsSalaryAdjustment)}
                                          onChange={this.onChangeChkSalary}
                                />
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={classes.expandDetail}>
                                <Row>
                                    {getDynamicFields && getDynamicFields.map((data, index) => {
                                        if (data.Disabled) return null;
                                        let no          = data && data.Code ? data.Code.slice(-2) : "";
                                        const dataField = "ProBaseSalary" + no;
                                        return (<Col key={index} xs={12} sm={12} md={6} lg={6}>
                                                <NumberFormat
                                                    className={classes.numberInput}
                                                    customInput={TextField}
                                                    label={data.CaptionName + ' ' + Config.lang('DHR_Moi').toLowerCase()}
                                                    thousandSeparator={true}
                                                    decimalScale={data.Decimals ? data.Decimals : 0}
                                                    value={state[dataField] ? String(state[dataField]) : dataOldForm['TempBaseSalary' + no]}
                                                    onValueChange={e => this.handleChange(dataField, e)}
                                                    fullWidth
                                                    disabled={Number(data?.AppStatusID) === 4}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    variant={"standard"}
                                                    margin={"normal"}
                                                />
                                            </Col>);
                                    })}
                                    {getCaption && getCaption.map((data, index) => {
                                        if (data.Disabled) return null;
                                        let no          = data && data.Code ? data.Code.slice(-2) : "";
                                        const dataField = "NewSalCoefficient" + no;
                                        return (<Col key={index} xs={12} sm={12} md={6} lg={6}>
                                                <NumberFormat
                                                    className={classes.numberInput}
                                                    customInput={TextField}
                                                    label={data.CaptionName + ' ' + Config.lang('DHR_Moi').toLowerCase()}
                                                    thousandSeparator={true}
                                                    decimalScale={data.Decimals ? data.Decimals : 0}
                                                    value={state[dataField] ? String(state[dataField]) : dataOldForm['SalCoefficient' + no]}
                                                    onValueChange={e => this.handleChange(dataField, e)}
                                                    fullWidth
                                                    disabled={Number(data?.AppStatusID) === 4}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    variant={"standard"}
                                                    margin={"normal"}
                                                />
                                            </Col>);
                                    })}
                                </Row>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </FormGroup>
                    <FormGroup className={"mgt10"}>
                        <ExpansionPanel className={classes.expandPanel + " mgb10"}
                                        onChange={(e, isExpaned) => {
                                            this.setState({isExpanded: isExpaned})
                                        }}>
                            <ExpansionPanelSummary
                                href={""}
                                className={classes.expandSummary}
                                // expandIcon={<img alt={""} src={require('../../../../assets/images/icon-expand.svg')} />}
                                aria-label="Expand"
                            >
                                <h5><b>{Config.lang("DHR_Thong_tin_cu")}</b></h5>&nbsp;
                                {isExpanded ?
                                    <Image alt={""}
                                           src={require('../../../../assets/images/icon-collapse.svg')}/>
                                    :
                                    <Image alt={""}
                                           src={require('../../../../assets/images/icon-expand.svg')}/>
                                }
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={classes.expandDetail}>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <span style={{fontWeight: 600}}>{Config.lang("DHR_Chuc_vu")}:</span>&nbsp;
                                        {dataOldForm.TempDutyName || ""}
                                    </Col>
                                </Row>
                                <Row>
                                    {getDynamicFields && getDynamicFields.map((field, indx) => {
                                        if (field.Disabled) return null;
                                        const no = field && field.Code ? field.Code.slice(-2) : "";
                                        const dataField = "TempBaseSalary" + no;
                                        return (
                                            <Col key={indx} xs={12} sm={6} md={6} lg={6}>
                                                <span style={{fontWeight: 600}}>{field.CaptionName || ""}:</span>&nbsp;
                                                {Number(dataOldForm[dataField] || 0).toLocaleString()}
                                            </Col>
                                        );
                                    })}
                                    {getCaption && getCaption.map((field, indx) => {
                                        if (field.Disabled) return null;
                                        const no = field && field.Code ? field.Code.slice(-2) : "";
                                        const dataField = "SalCoefficient" + no;
                                        return (
                                            <Col key={indx} xs={12} sm={6} md={6} lg={6}>
                                                <span style={{fontWeight: 600}}>{field.CaptionName || ""}:</span>&nbsp;
                                                {Number(employee?.[dataField] || 0).toLocaleString()}
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </FormGroup>
                    <FormGroup style={{marginBottom: 34}}>
                        <Attachments
                            ref={ref => this.attRef = ref}
                            showButton={true}
                            files={dataOldAttachments}
                            disabled={loading || uploading || Number(data?.AppStatusID) === 4}
                            maxLength={5}
                            uploading={loading}
                            onUploading={this.onUploading}
                            onChanged={this.onChangeAttachments}
                        />
                    </FormGroup>
                </Modal.Content>
                <Modal.Actions style={{justifyContent: "space-between"}}>
                    <div>
                        <IconButton href={''}
                            aria-label="view"
                            disabled={loading || disabled || uploading}
                            onClick={this.onAttachment}
                        >
                            <AttachFileIcon/>
                        </IconButton>
                    </div>
                    <div>
                        <ButtonGeneral
                            name={Config.lang("DHR_Luu")}
                            typeButton={"save"}
                            disabled={loading || uploading || mode === "view" ? Number(data?.AppStatusID) !== 0 : false}
                            style={{textTransform: "uppercase"}}
                            size={"large"}
                            onClick={this.onSave}
                        />
                    </div>
                </Modal.Actions>
            </>
        );
    }
}

W09F2150Popup.defaultProps = {
};
W09F2150Popup.propTypes    = {
    open: PropTypes.bool,
    mode: PropTypes.string,
    data: PropTypes.any,

    onClose: PropTypes.func
};

export default compose(connect(state => ({
    getCboDuty:              state.general.getCboDuty2,
    getCboEmployees:         state.general.getCboEmployees,
    loadForm:                state.W09F2150.loadForm,
    getDynamicFields:        state.W09F2150.getDynamicFields,
    getCaption:              state.W09F2150.getCaption,
    getAttachmentsByTransID: state.general.getAttachmentsByTransID
}), dispatch => ({
    W09F2150Actions: bindActionCreators(W09F2150Actions, dispatch),
    generalActions:  bindActionCreators(generalActions, dispatch)
})), withStyles(styles))(W09F2150Popup);
