/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/12/2020
 * @Example
 */
import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
import {Row, Col, FormGroup, Image} from "react-bootstrap";
import ButtonGeneral from "../../../common/button/button-general";
import withStyles from "@material-ui/core/styles/withStyles";
import {Combo, MForm, TextField} from "../../../common/form-material";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W09F2080Actions from "../../../../redux/W0X/W09F2080/W09F2080_actions";
import moment from "moment";
import DateBoxPicker from "../../../common/form-material/date-box"
import Attachments from "../../../common/attachments/attachments";
import IconButton from "@material-ui/core/IconButton";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Modal from "../../../common/modal/modal";
import {Checkbox, FormControlLabel, Tab, Tabs} from "@material-ui/core";
import TabContent, {TabPanel} from "../../../common/tabs/tab-content";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import CDN from "../../../CDN";
import NumberFormat from "react-number-format";
import * as W09F2150Actions from "../../../../redux/W0X/W09F2150/W09F2150_actions";
import {Dropdown} from "diginet-core-ui/components";
const styles = {
    expandPanel: {
        boxShadow: 'none'
    },
    expandSummary: {
        minHeight: '37px !important',
        padding: 0,
        '& .MuiExpansionPanelSummary-content': {
            margin: 0,
            '& .MuiButtonBase-root': {
                padding: '0 5px 0 0'
            }
        }
    },
    expandDetail: {
        display: 'block',
        padding: '0'
    }
};

class W09F2080Popup extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            iPermissionW09F5650: 0,
            showW09F2070Popup: false,
            tabKey: 0,
            isExpanded: false,
            disabled: false,
            error: {},
            loading: false,
            uploading: false,
            dataTeam: [],
            dataFormSalary: {},
            dataColumnSalary: [],
            dataOldAttachments: [],
            dataOldForm: {
                DepartmentID: "",
                TeamID: "",
                WorkID: "",
                ProjectID: "",
                DepartmentName: "",
                TeamName: "",
                WorkName: "",
                ProjectName: "",
                BASE01: 0,
                BASE02: 0,
                BASE03: 0,
                BASE04: 0,
            },
            dataForm: {
                Employee: null,
                NewDepartmentID: "",
                NewTeamID: "",
                NewWorkID: "",
                NewProject: null,
                ProContent: "",
                ProNotice: "",
                ValidDate: moment().format("YYYY-MM-DD"),
                NewBASE01: 0,
                NewBASE02: 0,
                NewBASE03: 0,
                NewBASE04: 0,
                IsSalaryAdjustment: 0

            },
            dataCboProjects: {
                rows: [],
                total: 0
            },
            projectsLoading: false,
        };
        this.attachments = [];
        this.deletedFile = [];
        this.filterCboProject = {
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.isChangeForm = false;
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F5650", (isPer) => {
            this.setState({iPermissionW09F5650: isPer});
        }, true);
    };

    loadCboProjects = (isReset) => {
        const param = {
            HostID: "",
            FormID: "W09F2080",
            Language: Config.language || "84",
            skip: this.filterCboProject.skip,
            limit: this.filterCboProject.limit,
            search: this.filterCboProject.strSearch
        };

        this.props.generalActions.getCboProjects(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboProjects} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    },
                });
            }
        });
    };

    loadCboDepartment = () => {
        const param = {
            HostID: "",
            FormID: "W09F2080",
            DivisionID: Config.getDivisionID(),
            Language: Config.language || "84"
        };

        this.props.generalActions.getCboDepartments(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboTeam = () => {
        const params = {
            DivisionID: Config.getDivisionID(),
            CompanyID: "",
            HostID: "",
            FormID: "W09F2080",
            Language: Config.language || "84"
        };
        this.props.generalActions.getCboTeam(params, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this.setState({dataTeam: data});
            }
        });
    };

    loadCboWorks = () => {
        const params = {
            Language: Config.language || "84"
        };
        this.props.generalActions.getCboWorks(params, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadForm = (EmployeeID) => {
        const {getCaption} = this.props;
        const param = {
            EmployeeID: EmployeeID || Config.getHREmployeeID()
        };
        this.setState({loading: true});
        this.props.w09f2080Actions.loadForm(param, (error, data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const dataOldForm= {
                        DepartmentID: data.DepartmentID ? data.DepartmentID : "",
                        TeamID: data.TeamID ? data.TeamID : "",
                        WorkID: data.WorkID ? data.WorkID : "",
                        ProjectID: data.ProjectID ? data.ProjectID : "",
                        DepartmentName: data.DepartmentName ? data.DepartmentName : "",
                        TeamName: data.TeamName ? data.TeamName : "",
                        WorkName: data.WorkName ? data.WorkName : "",
                        ProjectName: data.ProjectName ? data.ProjectName : "",
                        BASE01: data.BASE01 || data.BASE01 === 0 ? Number(data.BASE01) : 0,
                        BASE02: data.BASE02 || data.BASE02 === 0 ? Number(data.BASE02) : 0,
                        BASE03:data.BASE03 || data.BASE03 === 0 ? Number(data.BASE03) : 0,
                        BASE04: data.BASE04 || data.BASE04 === 0 ? Number(data.BASE04) : 0,
                };
                const dataForm= {
                    ...this.state.dataForm,
                    NewDepartmentID: data.DepartmentID ? data.DepartmentID : "",
                    NewTeamID: data.TeamID ? data.TeamID : "",
                    NewWorkID: data.WorkID ? data.WorkID : "",
                    NewProject: data.ProjectID ? {ProjectID: data.ProjectID ? data.ProjectID : "", ProjectName: data.ProjectName ? data.ProjectName : data.ProjectID ? data.ProjectID : ""} : null,
                    IsSalaryAdjustment: data.IsSalaryAdjustment ? data.IsSalaryAdjustment : 0,
                    NewBASE01: data.NewBASE01 ? data.NewBASE01 : 0,
                    NewBASE02: data.NewBASE02 ? data.NewBASE02 : 0,
                    NewBASE03: data.NewBASE03 ? data.NewBASE03 : 0,
                    NewBASE04: data.NewBASE04 ? data.NewBASE04 : 0,
                };
                getCaption.forEach(field => {
                    if (!field.Disabled) {
                        let no = field && field.Code ? field.Code.slice(-2) : "";
                        dataForm["NewSalCoefficient" + no]  = data["NewSalCoefficient" + no];
                        dataOldForm["SalCoefficient" + no]  = data["SalCoefficient" + no];
                    }
                });
                this.setState({
                    dataOldForm,
                    dataForm
                });
            }
        });
    };

    loadRequiredFields = () => {
        this.setState({loading: true});
        this.props.w09f2080Actions.getRequiredFields((error) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadDynamicColumnSalary = () => {
        const params = {
            Language: Config.language || 84
        };
        this.setState({loading: true});
        this.props.w09f2080Actions.getDynamicColumnSalary(params,(error,data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if(data) {
                this.setState({dataColumnSalary : data});
            }
        });
    };

    loadCboReasons = () => {
        this.setState({loading: true});
        this.props.w09f2080Actions.getCboReasons({TypeID: 3},(error) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };


    handleChange = (key, e) => {
        if (!key || !e) return false;
        const {mode} = this.props;
        if(!this.isChangeForm){
            this.isChangeForm = true;
        }
        switch (key) {
            case "EmployeeID":
                this._setDataForm("Employee", e.value, () => {
                    const EmployeeID = e && e.value && e.value.EmployeeID ? e.value.EmployeeID : "";
                    if (EmployeeID && mode === 'add') {
                        this.loadForm(EmployeeID);
                    }
                });
                break;
            case "ValidDate":
                this._setDataForm(key, e.value);
                break;
            case "NewDepartmentID":
                const {getCboTeam} = this.props;
                const departmentID = e.value;
                this._setDataForm(key, departmentID, () => {
                    if (departmentID && getCboTeam && getCboTeam.length > 0) {
                        this.setState({
                            dataTeam: getCboTeam.filter(team => team.DepartmentID === departmentID)
                        });
                    }
                });
                break;
            case "NewTeamID":
                this._setDataForm(key, e.value);
                break;
            case "ProjectID":
                this._setDataForm("NewProject", e.value);
                break;
            case "NewWorkID":
                this._setDataForm(key, e.value);
                break;
            case "ProContent":
                this._setDataForm(key, e?.value || e?.target?.value || "");
                break;
            case "ProNotice":
                this._setDataForm(key, e.target.value);
                break;
            default:
                let value = e.value ? e.value : (e.target && e.target.value ? e.target.value : "");
                this._setDataForm(key, value);
                break;
        }
    };

    componentDidMount = async () => {
        await this.loadPermission();
        const {mode, data} = this.props;
        this.loadCboProjects();
        this.loadCboDepartment();
        this.loadCboTeam();
        this.loadCboWorks();
        this.loadRequiredFields();
        this.loadDynamicColumnSalary();
        this.loadCaption();
        this.loadCboReasons();
        const _employeeID = Config.getHREmployeeID();
        if (mode === 'add') {
            const {iPermissionW09F5650} = this.state;
            if (iPermissionW09F5650 <= 2) {
                const user = Config.getUser({EmployeeID: _employeeID});
                const Employee = {
                    EmployeeID: _employeeID,
                    EmployeeName: user ? user.EmployeeName : "",
                    UserPictureURL: user ? user.UserPictureURL : "",
                };
                this._setDataForm('Employee', Employee);
                this._setDataForm('EmployeeID', _employeeID);
                this.loadForm(_employeeID);
            }
        } else {
            this.loadDataPopup();
            this.loadAttachments();
            if (data && (Number(data.AppStatusID) !== 0)) {
                this.setState({disabled: true});
            }
        }
    };

    loadDataPopup = () => {
        const {data} = this.props;
        const param = {
            ProTransID: data && data.ProTransID ? data.ProTransID : ""
        };
        this.setState({loading: true});
        this.props.w09f2080Actions.loadFormPopup(param, (error, data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this._initDataForm(data);
            }
        });
    };

    _initDataForm = (data) => {
        const {getCaption} = this.props;
        const EmployeeID = data && data.EmployeeID ? data.EmployeeID : "";
        const user = Config.getUser({EmployeeID: EmployeeID});
        const Employee = {
            EmployeeID: EmployeeID,
            EmployeeName: data && data.EmployeeName ? data.EmployeeName : (user ? user.EmployeeName : ""),
            UserPictureURL: data && data.UserPictureURL ? data.UserPictureURL : (user ? user.UserPictureURL : ""),
        };
        const ProjectID = data && data.NewProjectID ? data.NewProjectID : "";
        const NewProjectName = data && data.NewProjectName ? data.NewProjectName : ProjectID;

        let dataOldForm = {
            DepartmentID: data && data.DepartmentID ? data.DepartmentID : "",
            TeamID: data && data.TeamID ? data.TeamID : "",
            WorkID: data && data.WorkID ? data.WorkID : "",
            ProjectID: data && data.ProjectID ? data.ProjectID : "",
            DepartmentName: data && data.DepartmentName ? data.DepartmentName : "",
            TeamName: data && data.TeamName ? data.TeamName : "",
            WorkName: data && data.WorkName ? data.WorkName : "",
            ProjectName: data && data.ProjectName ? data.ProjectName : "",
            BASE01: data.BASE01 || data.BASE01 === 0 ? Number(data.BASE01) : 0,
            BASE02: data.BASE02 || data.BASE02 === 0 ? Number(data.BASE02) : 0,
            BASE03:data.BASE03 || data.BASE03 === 0 ? Number(data.BASE03) : 0,
            BASE04: data.BASE04 || data.BASE04 === 0 ? Number(data.BASE04) : 0,
        };
        let dataForm = {
            Employee: Employee,
            NewDepartmentID: data && data.NewDepartmentID ? data.NewDepartmentID : "",
            NewTeamID: data && data.NewTeamID ? data.NewTeamID : "",
            NewWorkID: data && data.NewWorkID ? data.NewWorkID : "",
            NewProject: ProjectID ? {ProjectID: ProjectID, ProjectName: NewProjectName} : null,
            ProContent: data && data.ProContent ? data.ProContent : "",
            ProNotice: data && data.ProNotice ? data.ProNotice : "",
            ValidDate: data && data.ValidDate ? moment.utc(data.ValidDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
            IsSalaryAdjustment: data.IsSalaryAdjustment ? Number(data.IsSalaryAdjustment) : 0,
            NewBASE01: data.NewBASE01 || data.NewBASE01 === 0 ? Number(data.NewBASE01)  : 0,
            NewBASE02: data.NewBASE02 || data.NewBASE02 === 0 ? Number(data.NewBASE02) : 0,
            NewBASE03: data.NewBASE03 || data.NewBASE03 === 0 ? Number(data.NewBASE03) : 0,
            NewBASE04: data.NewBASE04 || data.NewBASE04 === 0 ? Number(data.NewBASE04) : 0,

        };

        getCaption.forEach(field => {
            if (!field.Disabled) {
                let no = field && field.Code ? field.Code.slice(-2) : "";
                dataForm["NewSalCoefficient" + no]  = data["NewSalCoefficient" + no];
                dataOldForm["SalCoefficient" + no]  = data["SalCoefficient" + no];
            }
        });

        this.setState({
            dataOldForm,
            dataForm
        });
    };

    _setDataForm = (key, value, cb) => {
        if (!key) return false;
        this.setState({
            dataForm: {
                ...this.state.dataForm,
                [key]: value
            }
        }, () => {
            if (cb) cb();
        });
    };

    setStateErrorText(objValue, key) {
        const stateError = key ? key : "error";
        this.setState({
            [stateError]: {
                ...(this.state[stateError] ? this.state[stateError] : {}),
                ...objValue
            }
        });
        return Object.keys(objValue).length !== 0;
    }

    onSave = () => {
        const {mode, data, getRequiredFields, getCaption, getCboReasons} = this.props;
        const isEdit = mode === "view";
        const {dataForm, dataOldForm} = this.state;
        const listDataForm = Object.keys(dataForm);
        let dataRequired = ["EmployeeID", "ValidDate", "NewDepartmentID"];
        getRequiredFields && getRequiredFields.forEach((field) => {
            if (field.ValidMode && field.ValidMode === "O"
                && field.SqlFieldName && listDataForm.indexOf(field.SqlFieldName) > -1
                && dataRequired.indexOf(field.SqlFieldName) < 0
            ) {
                dataRequired.push(field.SqlFieldName);
            }
        });
        //validate form..
        const validateRules = [];
        dataRequired && dataRequired.forEach((item) => {
            let rule = {};
            switch (item) {
                case "EmployeeID":
                    rule = {
                        name: item,
                        rules: ["isRequired"],
                        value: dataForm && dataForm.Employee && dataForm.Employee.EmployeeID ? dataForm.Employee.EmployeeID : ""
                    };
                    break;
                case "ProjectID":
                    rule = {
                        name: item,
                        rules: ["isRequired"],
                        value: dataForm && dataForm.NewProject && dataForm.NewProject.ProjectID ? dataForm.NewProject.ProjectID : ""
                    };
                    break;
                default:
                    rule = {
                        name: item,
                        rules: ["isRequired"],
                        value: dataForm[item]
                    };
                    break;
            }
            validateRules.push(rule);
        });
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            this.setStateErrorText(validateForm);
            return false;
        } else {
            const arrAttachments = this._getAttachments();
            const dtReason = getCboReasons.find(i => i.ReasonID === dataForm.ProContent);
            const params = {
                DivisionID: Config.getDivisionID(),
                HostID: "",
                Language: Config.language || "84",
                EmployeeID: dataForm && dataForm.Employee && dataForm.Employee.EmployeeID ? dataForm.Employee.EmployeeID : "",
                ValidDate: dataForm && dataForm.ValidDate ? dataForm.ValidDate : "",
                ProContent: dtReason?.ProContent || dataForm.ProContent || "",
                ProNotice: dataForm && dataForm.ProNotice ? dataForm.ProNotice : "",

                TempDepartmentID: dataOldForm && dataOldForm.DepartmentID ? dataOldForm.DepartmentID : "",
                TempTeamID: dataOldForm && dataOldForm.TeamID ? dataOldForm.TeamID : "",
                TempWorkID: dataOldForm && dataOldForm.WorkID ? dataOldForm.WorkID : "",
                TempProjectID: dataOldForm && dataOldForm.ProjectID ? dataOldForm.ProjectID : "",

                ProDepartmentID: dataForm && dataForm.NewDepartmentID ? dataForm.NewDepartmentID : "",
                ProTeamID: dataForm && dataForm.NewTeamID ? dataForm.NewTeamID : "",
                ProWorkID: dataForm && dataForm.NewWorkID ? dataForm.NewWorkID : "",
                ProProjectID: dataForm && dataForm.NewProject && dataForm.NewProject.ProjectID ? dataForm.NewProject.ProjectID : "",

                TranMonth: Config.getHRTransMonth(),
                TranYear: Config.getHRTransYear(),
                IsSalaryAdjustment: dataForm && dataForm.IsSalaryAdjustment ? Number(dataForm.IsSalaryAdjustment) : 0,
                ProBaseSalary01: Number(dataForm.IsSalaryAdjustment) === 0 ? 0 : (dataForm && dataForm.NewBASE01 ? Number(dataForm.NewBASE01) : 0),
                ProBaseSalary02: Number(dataForm.IsSalaryAdjustment) === 0 ? 0 : (dataForm && dataForm.NewBASE02 ? Number(dataForm.NewBASE02) : 0),
                ProBaseSalary03: Number(dataForm.IsSalaryAdjustment) === 0 ? 0 : (dataForm && dataForm.NewBASE03 ? Number(dataForm.NewBASE03) : 0),
                ProBaseSalary04: Number(dataForm.IsSalaryAdjustment) === 0 ? 0 : (dataForm && dataForm.NewBASE04 ? Number(dataForm.NewBASE04) : 0),
                arrAttachment: JSON.stringify(arrAttachments)
            };
            getCaption.forEach(field => {
                if (!field.Disabled) {
                    let no = field && field.Code ? field.Code.slice(-2) : "";
                    params["NewSalCoefficient" + no]  = Number(dataForm.IsSalaryAdjustment) === 0 ? 0 : (dataForm?.["NewSalCoefficient" + no] ? Number(dataForm["NewSalCoefficient" + no] || 0) : 0);
                }
            });
            if (isEdit) {
                params.ProTransID = data && data.ProTransID ? data.ProTransID : "";
            }

            this.setState({loading: true});
            this.props.w09f2080Actions[isEdit ? "update" : "save"](params, (error, data) => {
                this.setState({loading: false});
                if (error) {
                    error.customMessage = {
                        "F2000E002" : "Mode " + Config.lang("Bat_buoc"),
                        "F2000E003" : "LeaveTypeID " + Config.lang("Bat_buoc"),
                        "F2000E004" : "IsRegisterType " + Config.lang("Bat_buoc"),
                        "F2000E001" : Config.lang("Luu_khong_thanh_cong"),
                    };
                    Config.popup.show("ERROR", error);
                    return false;
                }
                if (data) {
                    if (data.Status === 1) {
                        Config.popup.show('INFO', data.Message);
                        return false;
                    } else if (data.Status === 0) {
                        this._removeCDN();
                        this.props.onClose(true);
                        Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                    } else {
                        Config.popup.show('INFO', Config.lang("Luu_khong_thanh_cong"));
                        return false;
                    }
                }
            });
        }

    };

    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1]
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    handleTabChange = (e, key) => {
        this.setState({tabKey: key});
    };

    resetForm = () => {
        this.setState({
            dataOldForm: {
                DepartmentID: "",
                TeamID: "",
                WorkID: "",
                ProjectID: "",
                DepartmentName: "",
                TeamName: "",
                WorkName: "",
                ProjectName: "",
                BASE01: 0,
                BASE02: 0,
                BASE03: 0,
                BASE04: 0,

            },
            dataForm: {
                Employee: null,
                NewDepartmentID: "",
                NewTeamID: "",
                NewWorkID: "",
                NewProject: null,
                ProContent: "",
                ProNotice: "",
                ValidDate: moment().format("YYYY-MM-DD"),
                IsSalaryAdjustment: 0,
                NewBASE01: 0,
                NewBASE02: 0,
                NewBASE03: 0,
                NewBASE04: 0,
            }
        });
    };

    onClose = () => {
        const {onClose} = this.props;
        this.resetForm();
        if (onClose) onClose();
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const {dataOldAttachments} = this.state;

        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    loadAttachments = () => {
        const {data} = this.props;
        const param = {
            KeyID: data && data.ProTransID ? data.ProTransID : "",
        };
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data
                });
            }
        });
    };

    onUploading = (value) => {
        this.setState({uploading: value});
    };

    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
          this.deletedFile = [ ...e.deletedFiles];
          const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
          this.setState(prevState => ({
            dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
              return _arrRemove.indexOf(att.AttachmentID) < 0;
            })
          }));
        }
      };

    handleChangeDataSalary = (index,data) => {
        this._setDataForm(index, data.value);
    };

    onChangeChkSalary = () => {
        const { dataForm, dataOldForm, dataColumnSalary } = this.state;
        this.setState({
            dataForm: {
                ...this.state.dataForm,
                IsSalaryAdjustment: !dataForm.IsSalaryAdjustment,
            }
        }, () => {
            const {IsSalaryAdjustment} = this.state.dataForm;
            if (IsSalaryAdjustment) {
                let obj = {};
                dataColumnSalary && dataColumnSalary.forEach(d => {
                    let key              = d.Code.slice(-2);
                    obj["NewBASE" + key] = dataOldForm["BASE" + key];
                });
                this.setState({
                    dataForm: {
                        ...this.state.dataForm,
                        ...obj
                    }
                });
            }
        });
    };

    loadCaption = () => {
        this.props.w09F2150Actions.loadCaption({Language: Config.language || "84" },(error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    render() {
        let {classes, getCboDepartments, getCboWorks, mode, getCaption, getCboReasons,
        } = this.props;
        const {iPermissionW09F5650, isExpanded, uploading, dataForm, dataOldForm, error, loading, dataOldAttachments, disabled, tabKey,
            dataTeam, dataCboProjects, projectsLoading, dataColumnSalary
        } = this.state;
        return (
            <>
                <Modal.Content>
                    <Tabs
                        value={tabKey}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        component={"div"}
                        variant="scrollable"
                        scrollButtons="off"
                        style={{fontSize: '16px'}}
                        aria-label="full width tabs"
                    >
                        <Tab href={""} label={Config.lang("Thong_tin_chinh")}/>
                        <Tab href={""} label={Config.lang("Mo_rong")}/>
                    </Tabs>
                    <TabContent
                        style={{padding: "10px 0", backgroundColor: '#fff'}}
                        activeKey={tabKey}
                        disableSwipe={true}
                        lazyLoading={false}
                        onChangeIndex={this.handleChangeIndex}>
                        <TabPanel index={0}>
                            <React.Fragment>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <Combo.Employees
                                            acceptCustomValue={true}
                                            error={error && error["EmployeeID"]}
                                            displayExpr={'EmployeeName'}
                                            keyExpr={'EmployeeID'}
                                            valueExpr={'EmployeeID'}
                                            subTextExpr={"EmployeeID"}
                                            value={dataForm.Employee}
                                            FormID={"W09F2080"}
                                            stylingMode={'underlined'}
                                            shrink={true}
                                            required={true}
                                            disabled={mode === "view" || loading || disabled || iPermissionW09F5650 <= 2}
                                            label={Config.lang('Nhan_vien')}
                                            dropdownProps={{
                                                readOnly: disabled
                                            }}
                                            onValueChanged={e => this.handleChange("EmployeeID", e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <DateBoxPicker
                                            label={Config.lang("Ngay_hieu_luc")}
                                            placeholder={Config.lang("Chon_ngay")}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            required={true}
                                            width={"100%"}
                                            stylingMode={"underlined"}
                                            shrink={true}
                                            value={dataForm.ValidDate}
                                            onValueChanged={e => this.handleChange("ValidDate", e)}
                                            margin={"normal"}
                                            disabled={disabled || loading}
                                            error={error && error["ValidDate"]}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <Combo
                                            error={error && error["NewDepartmentID"]}
                                            dataSource={Config.storeDataSoureDevExtreme(getCboDepartments)}
                                            displayExpr={"DepartmentName"}
                                            valueExpr={"DepartmentID"}
                                            stylingMode={"underlined"}
                                            disabled={disabled || loading}
                                            searchEnabled={true}
                                            shrink={true}
                                            required={true}
                                            value={dataForm.NewDepartmentID}
                                            label={Config.lang("Phong_ban_moi")}
                                            onValueChanged={e => this.handleChange("NewDepartmentID", e)}
                                            margin={"normal"}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <Combo
                                            error={error && error["NewTeamID"]}
                                            dataSource={Config.storeDataSoureDevExtreme(dataTeam)}
                                            displayExpr={"TeamName"}
                                            valueExpr={"TeamID"}
                                            stylingMode={"underlined"}
                                            disabled={disabled || loading}
                                            searchEnabled={true}
                                            shrink={true}
                                            value={dataForm.NewTeamID}
                                            label={Config.lang("To_nhom_moi")}
                                            onValueChanged={e =>
                                                this.handleChange("NewTeamID", e)
                                            }
                                            margin={"normal"}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <Combo.LoadMore
                                            error={error && error["ProjectID"]}
                                            dataSource={dataCboProjects}
                                            skip={this.filterCboProject.skip}
                                            limit={this.filterCboProject.limit}
                                            displayExpr={'ProjectName'}
                                            keyExpr={'ProjectID'}
                                            valueExpr={'ProjectID'}
                                            value={dataForm.NewProject}
                                            loading={projectsLoading}
                                            stylingMode={'underlined'}
                                            label={Config.lang('Du_an')}
                                            disabled={disabled || loading}
                                            shrink={true}
                                            margin={"normal"}
                                            itemRender={(e) => {
                                                const {data} = e;
                                                if (!data) return null;
                                                return data.ProjectID + ' - ' + data.ProjectName;
                                            }}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={e => this.handleChange("ProjectID", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboProject.strSearch = e.target.value;
                                                this.filterCboProject.skip = 0;
                                                this.loadCboProjects(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboProject.skip = e.skip;
                                                this.filterCboProject.limit = e.limit;
                                                this.loadCboProjects();
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <Combo
                                            error={error && error["NewWorkID"]}
                                            dataSource={Config.storeDataSoureDevExtreme(getCboWorks)}
                                            displayExpr={"WorkName"}
                                            valueExpr={"WorkID"}
                                            stylingMode={"underlined"}
                                            disabled={disabled || loading}
                                            searchEnabled={true}
                                            shrink={true}
                                            value={dataForm.NewWorkID}
                                            label={Config.lang("Cong_viec")}
                                            onValueChanged={e => this.handleChange("NewWorkID", e)}
                                            margin={"normal"}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            error={error && error["ProContent"]}
                                            clearAble
                                            valueObjectDefault={ mode === "view" && !this.isChangeForm ? {
                                                ReasonID: "",
                                                ProContent: dataForm.ProContent,
                                            } : null}
                                            valueExpr={"ProContent"}
                                            renderSelectedItem={e => e?.data?.ProContent ?? ""}
                                            renderItem={e => {
                                                if(!e) return null;
                                                const {data} = e;
                                                return `${data?.ReasonID ?? ""} 
                                                        ${data?.ReasonID ? "-" : ""}
                                                        ${data?.ProContent ?? ""}`
                                            }}
                                            label={Config.lang("Ly_do")}
                                            onChange={e => this.handleChange("ProContent", e)}
                                            dataSource={getCboReasons}
                                            disabled={disabled || loading}
                                            value={dataForm.ProContent}
                                            onInput={e => this.handleChange("ProContent", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextField
                                            error={error && error["ProNotice"]}
                                            label={Config.lang("Ghi_chu")}
                                            variant={"standard"}
                                            margin={"normal"}
                                            value={dataForm.ProNotice}
                                            disabled={disabled || loading}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            onChange={e => this.handleChange("ProNotice", e)}
                                            fullWidth
                                        />
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <ExpansionPanel
                                        expanded={Boolean(dataForm.IsSalaryAdjustment)}
                                        className={classes.expandPanel}
                                    >
                                        <ExpansionPanelSummary
                                            className={classes.expandSummary}
                                            style={{ padding: 0}}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox  checked={Boolean(dataForm.IsSalaryAdjustment)}
                                                                    onChange={this.onChangeChkSalary}
                                                                     />}
                                                label={Config.lang("De_xuat_dieu_chinh_luong")}
                                            />

                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className={classes.expandDetail}>
                                            <Row>
                                                {
                                                    Number(dataForm.IsSalaryAdjustment) === 1 && dataColumnSalary && dataColumnSalary.map((data, index) => {
                                                        if(data.Disabled) return null;
                                                        let key = data.Code.slice(-2);
                                                        const value = dataForm["NewBASE" + key] || dataForm["NewBASE" + key] === 0 ? Number(dataForm["NewBASE" + key]) : 0;
                                                        return(
                                                            <Col key={index} xs={12} sm={12} md={6} lg={6}>
                                                                <NumberFormat
                                                                    customInput={TextField}
                                                                    label={data.CaptionName + ' ' + Config.lang('Moi').toLowerCase()}
                                                                    thousandSeparator={true}
                                                                    decimalScale={data.Decimals ? data.Decimals : 0}
                                                                    value={String(value)}
                                                                    onValueChange={e => this.handleChangeDataSalary("NewBASE" + key,e)}
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                    variant={"standard"}
                                                                    margin={"normal"}
                                                                />
                                                            </Col>
                                                        )
                                                    })
                                                }
                                                {
                                                    Number(dataForm.IsSalaryAdjustment) === 1 && getCaption && getCaption.map((data, index) => {
                                                        if(data.Disabled) return null;
                                                        let key = data.Code.slice(-2);
                                                        const value = dataForm["NewSalCoefficient" + key] || dataForm["NewSalCoefficient" + key] === 0 ? Number(dataForm["NewSalCoefficient" + key]) : 0;
                                                        return(
                                                            <Col key={index} xs={12} sm={12} md={6} lg={6}>
                                                                <NumberFormat
                                                                    customInput={TextField}
                                                                    label={data.CaptionName + ' ' + Config.lang('Moi').toLowerCase()}
                                                                    thousandSeparator={true}
                                                                    decimalScale={data.Decimals ? data.Decimals : 0}
                                                                    value={String(value)}
                                                                    onValueChange={e => this.handleChangeDataSalary("NewSalCoefficient" + key,e)}
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                    variant={"standard"}
                                                                    margin={"normal"}
                                                                />
                                                            </Col>
                                                        )
                                                    })
                                                }

                                            </Row>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </FormGroup>
                                <FormGroup className={"mgt10"}>
                                    <ExpansionPanel className={classes.expandPanel + " mgb10"}
                                                    onChange={(e, isExpaned) => {
                                                        this.setState({isExpanded: isExpaned})
                                                    }}>
                                        <ExpansionPanelSummary
                                            href={""}
                                            className={classes.expandSummary}
                                            // expandIcon={<img alt={""} src={require('../../../../assets/images/icon-expand.svg')} />}
                                            aria-label="Expand"
                                        >
                                            <h5><b>{Config.lang("Thong_tin_cu")}</b></h5>&nbsp;
                                            {isExpanded ?
                                                <Image alt={""}
                                                       src={require('../../../../assets/images/icon-collapse.svg')}/>
                                                :
                                                <Image alt={""}
                                                       src={require('../../../../assets/images/icon-expand.svg')}/>
                                            }
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className={classes.expandDetail}>
                                            <Row>
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <span style={{fontWeight: 600}}>{Config.lang("Phong_ban")}:</span>&nbsp;
                                                    {dataOldForm.DepartmentName || ""}
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <span style={{fontWeight: 600}}>{Config.lang("To_nhom")}:</span>&nbsp;
                                                    {dataOldForm.TeamName || ""}
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <span style={{fontWeight: 600}}>{Config.lang("Du_an")}:</span>&nbsp;
                                                    {dataOldForm.ProjectName || ""}
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <span style={{fontWeight: 600}}>{Config.lang("Cong_viec")}:</span>&nbsp;
                                                    {dataOldForm.WorkName || ""}
                                                </Col>
                                                {
                                                    dataColumnSalary && dataColumnSalary.map((data, index) => {
                                                        if(data.Disabled) return null;
                                                        let key = data.Code.slice(-2);
                                                        return(
                                                             <Col key={index} xs={12} sm={6} md={6} lg={6}>
                                                                <span style={{fontWeight: 600}}>{data.CaptionName}:</span>&nbsp;
                                                                {dataOldForm["BASE" + key] || dataOldForm["BASE" + key] === 0 ?   new Intl.NumberFormat().format(Number(dataOldForm["BASE" + key])) : ""}
                                                            </Col>
                                                        )
                                                    })
                                                }
                                                {getCaption && getCaption.map((field, indx) => {
                                                    if (field.Disabled) return null;
                                                    const no = field && field.Code ? field.Code.slice(-2) : "";
                                                    const dataField = "SalCoefficient" + no;
                                                    return (
                                                        <Col key={indx} xs={12} sm={6} md={6} lg={6}>
                                                            <span style={{fontWeight: 600}}>{field.CaptionName || ""}:</span>&nbsp;
                                                            {Number(dataOldForm[dataField] || 0).toLocaleString()}
                                                        </Col>
                                                    );
                                                })}
                                            </Row>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </FormGroup>
                                <FormGroup style={{marginBottom: 34}}>
                                    <Attachments
                                        ref={ref => this.attRef = ref}
                                        showButton={true}
                                        files={dataOldAttachments}
                                        disabled={disabled || loading || uploading}
                                        maxLength={5}
                                        uploading={loading}
                                        onUploading={this.onUploading}
                                        onChanged={this.onChangeAttachments}
                                    />
                                </FormGroup>
                            </React.Fragment>
                        </TabPanel>
                        <TabPanel index={1}>
                            <React.Fragment>

                            </React.Fragment>
                        </TabPanel>
                    </TabContent>
                </Modal.Content>
                <Modal.Actions style={{ justifyContent: "space-between" }}>
                    <div>
                        <IconButton aria-label="view" disabled={loading || uploading} onClick={this.onAttachment}>
                            <AttachFileIcon/>
                        </IconButton>
                    </div>
                    <div>
                        <ButtonGeneral
                            name={Config.lang("Luu")}
                            typeButton={"save"}
                            disabled={loading || disabled || uploading}
                            style={{textTransform: "uppercase"}}
                            size={"large"}
                            onClick={this.onSave}
                        />
                    </div>

                </Modal.Actions>
            </>
        );
    }
}

W09F2080Popup.propTypes = {
    open: PropTypes.bool,
    mode: PropTypes.string,
    data: PropTypes.any,

    onClose: PropTypes.func
};

export default compose(
    connect(
        state => ({
            getCboDepartments: state.general.getCboDepartments,
            getCboTeam: state.general.getCboTeam,
            getCboWorks: state.general.getCboWorks,
            getRequiredFields: state.W09F2080.getRequiredFields,
            getCboReasons: state.W09F2080.getCboReasons,
            getAttachmentsByTransID: state.general.getAttachmentsByTransID,
            getCaption: state.W09F2150.getCaption,
        }),
        dispatch => ({
            w09f2080Actions: bindActionCreators(W09F2080Actions, dispatch),
            w09F2150Actions: bindActionCreators(W09F2150Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch)
        })
    ),
    withStyles(styles)
)(W09F2080Popup);
